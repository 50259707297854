import MainHeader from '../../components/MainHeader';
import classes from "./TimeInOutV2.module.css";
import React, { useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import wcmatch from 'wildcard-match';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import SelfieCameraModalV2 from '../../components/SelfieCameraModalV2';
import { collection, getDocs } from 'firebase/firestore/lite';
import { db } from '../../firebaseConfig';


const TimeInOutV2 = () => {

  // Data States
  // Orig User list state
  const [origUserList, setOrigUserList] = useState([]);
  // User list state
  const [userList, setUserList] = useState([]);
  // // User list state
  // const [origUserList, setOrigUserList] = useState([]);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  // Input states
  // Display Name input form listener
  const [selectedUser, setSelectedUser] = useState('');


  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    firebaseObserver.subscribe('authOperatorClaim', isOperator => {
      if (!isOperator) {
        navigate('/home');
      }
    });
    listAllUsers();
    return () => {
      firebaseObserver.unsubscribe('authStateChanged');
      firebaseObserver.unsubscribe('authOperatorClaim');
    }
  },[]);
  /* eslint-enable */
  // End Firebase Auth Context Management


  async function listAllUsers() {
    setLoaderState(true);
    const employeesRef = collection(db, "employees");
    const snapshot = await getDocs(employeesRef);
    const userList = snapshot.docs.map(doc => doc.data());
    const filteredUserList = userList.filter((user) => {
      return user.hasOwnProperty('displayName') && user.displayName !== "operator";
    });
    filteredUserList.sort((a, b) => a.displayName?.toUpperCase() > b.displayName?.toUpperCase() ? 1 : -1);
    setUserList(filteredUserList);
    setOrigUserList(filteredUserList);
    setLoaderState(false);
  }

  function selectUserHandler(user) {
    setSelectedUser(user);
  }

  // Search DisplayName Dynamically
  function searchDisplayName(event) {
    const displayName = event.target.value;
    const isMatch = wcmatch(`*${displayName}*`);
    const myData = [];
    origUserList.forEach((user) => {
      if (user.labelDisplayName && isMatch(user.labelDisplayName)) {
        myData.push(user);
      }
      else if (isMatch(user.displayName)) {
        myData.push(user);
      }
    });
    setUserList(myData);
    setSelectedUser('');
  }

  function doNothing(event){
    if(event.key === 'Enter'){
      event.preventDefault();
    }
  }

  function getTimeInOutConfirmation(timeInOutSuccess) {
    if (timeInOutSuccess){
      setSelectedUser('');
    } else {
      window.location.reload(false);
    }
  };

  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
    <center>
      <h4 style={{marginTop:20}}>Time In or Out</h4>
      <div className={classes.inputBox}>
      <Form>
        <br></br>
        <label htmlFor='displayName'>Find Username:</label> &nbsp;
        <input type='displayName' id='displayName' autoComplete="off" onChange={searchDisplayName} onKeyDown={doNothing} />
        <br></br>
        <br></br>
      </Form>
        { selectedUser
          ? <div>
              <h4>{selectedUser.labelDisplayName || selectedUser.displayName}</h4>
              <br></br>
              <SelfieCameraModalV2
                selectedUser={selectedUser}
                show={true}
                onTimeInOutConfirmation={getTimeInOutConfirmation}
              />
              &nbsp; <Button variant='info' onClick={searchDisplayName}>Change User</Button>
          </div>
          : <div>
            <ListGroup style={{overflowY:"scroll", maxHeight:"250px", WebkitOverflowScrolling: "touch"}}>
              {
                userList.map((user) => (
                  <ListGroup.Item key={user.displayName} action onClick={() => selectUserHandler(user)}>
                    {user.labelDisplayName || user.displayName}
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          </div>
        }
      </div>
    </center>
    </div>
    </CustomLoader>
  );
};

export default TimeInOutV2;
