import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  render() {

    const getMapOptions = (maps) => {
      return {
        disableDefaultUI: true,
        mapTypeControl: true,
        streetViewControl: true,
        styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
      };
    };

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: this.props.height, width: this.props.width }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyA0RRy42raVl4oMNto5sesaENJd99KnZAk" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={getMapOptions}
        >
        {this.props.markers ? this.props.markers.map((coords) => (
          <Marker key={coords.lat}
            lat={coords.lat}
            lng={coords.lng}
            color="#1133e1"
          />
        ))
        : null }
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
