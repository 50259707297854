import MainHeader from '../../components/MainHeader';
import classes from "./FileLeaves.module.css";
import React, { useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import Button from 'react-bootstrap/Button';
import { db, firebaseObserver, functions } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import { storage } from "../../firebaseConfig";
import { useAuthContext } from "../../components/context/useAuthContext";
import { httpsCallable } from 'firebase/functions';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// import Compressor from 'compressorjs';

const FileLeaves = () => {
  // User Auth Context
  const { user } = useAuthContext();

  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Display Name input form listener
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Radio Buttons
  // wholeday checkbox state
  const [isWholeday, setIsWholeday] = useState(true);
  // halfday checkbox state
  const [isHalfday, setIsHalfday] = useState(false);
  // multiday checkbox state
  const [isMultiday, setIsMultiday] = useState(false);
  // Input states
  // Leave Reason Input
  const [reason, setReason] = useState('');
  // State to store uploaded file uri for preview
  const [fileUri, setFileUri] = useState("");
  // State to store uploaded file
  const [file, setFile] = useState("");
  // leaveDates array state
  const [leaveDates, setLeaveDates] = useState(['']);
  // leave count state
  const [leaveCount, setleaveCount] = useState(0);
  // remaining leave balance state
  const [leaveBalance, setLeaveBalance] = useState(0);
  // Leave Type
  const [leaveType, setLeaveType] = useState("Vacation Leave");
  // Leave Type Key
  const [leaveTypeKey, setLeaveTypeKey] = useState("vacationLeaves");
  // Ouput/Result States
  // Create results State
  const [createResult, setCreateResult] = useState('');

  // OLD colde, keep until next major release
  // // let userEmail;
  // const navigate = useNavigate()
  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(function(user) {
  //     if (user) {
  //         user.getIdTokenResult().then(function(data) {
  //           setDisplayName(user.displayName);
  //         }
  //       );
  //     } else {
  //       alert('Unauthorized access, please login.');
  //       navigate('/');
  //     }
  //   });
  //   if (displayName) {
  //     setLoaderState(true);
  //     getLeaveBalance(displayName);
  //     setLoaderState(false);
  //   }
  // },[displayName]);


  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    if (displayName) {
      setLoaderState(true);
      getLeaveBalance(displayName, leaveType, leaveTypeKey);
      setLoaderState(false);
    }
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  ///// Event Handlers ////
  function validateStateVariables() {
    if (!file) {
      alert(`Approval picture is required.`);
      return false;
    }
    if (!reason) {
      alert(`Leave reason is required.`);
      return false;
    }
    if (!leaveDates || leaveDates[0] === '') {
      alert(`Leave date is required.`);
      return false;
    }
    return true;
  }

  async function uploadToFirebaseStorage(storageRef, file) {
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }

  async function fileLeaveClickHandler() {
    // Break function if not validated
    if (!validateStateVariables()) {
      return;
    };
    // Validate if Leave already filed previously

    // Use current date for datefiling
    const fileName = DateTime.local().toFormat('dd-MMM-yyyy').concat('.').concat(file.name.split('.').pop());
    const storageRef = ref(storage, `/leaves/${displayName}/${fileName}`);
    // let compressedFile;
    // new Compressor(file, {
    //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {
    //     // compressedResult has the compressed file.
    //     // Use the compressed file to upload the images to your server.
    //     compressedFile = compressedResult;
    //   },
    // });
    // const approvalImageUrl = await uploadToFirebaseStorage(storageRef, compressedFile);
    const approvalImageUrl = await uploadToFirebaseStorage(storageRef, file);
    // console.log({
    //         // trim blank items
    //         leaveDates: leaveDates.filter(n => n),
    //         count: leaveCount,
    //         approvalImageUrl: approvalImageUrl,
    //         reason: reason,
    //         displayName: displayName
    //       });
    // Enable loader when request is being sent.
    setLoaderState(true);
    const fileEmployeeLeave = httpsCallable(functions, 'fileEmployeeLeave');
    await fileEmployeeLeave({
      // trim blank items
      leaveDates: leaveDates.filter(n => n),
      count: leaveCount,
      approvalImageUrl: approvalImageUrl,
      reason: reason,
      displayName: displayName,
      leaveType: leaveType
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        if (response?.data?.httpErrorCode) {
          alert(response.data.details);
        } else {
          // Action to trigger after success.
          // console.log(response.data.result);
          setCreateResult(response.data);
          setLeaveDates(['']);
          setFile('');
          setFileUri('');
          setReason('');
          setleaveCount(0);
          getLeaveBalance(displayName, leaveType, leaveTypeKey);
        }
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  function removeDateHandler(index) {
    const tempLeavesArray = [].concat(leaveDates);
    // Do not delete first item. Just blank out the date
    if (index === 0) {
      tempLeavesArray[index] = '';
    } else {
      tempLeavesArray.splice(index,1);
    }
    setLeaveDates(tempLeavesArray);
    setleaveCount(calcLeaveCount(tempLeavesArray), leaveType);
  }

  function addDateHandler() {
    const tempLeavesArray = [].concat(leaveDates);
    if (tempLeavesArray[tempLeavesArray.length -1] === '') {
      alert('Please select a leave date before adding a new one.')
      return;
    }
    tempLeavesArray.push('');
    setLeaveDates(tempLeavesArray);
    setleaveCount(calcLeaveCount(tempLeavesArray), leaveType);
  }

  function fileChangeHandler(event) {
    if (event.target.files.length !== 0) {
      setFile(event.target.files[0]);
      setFileUri(URL.createObjectURL(event.target.files[0]));
    }
  }

  async function leaveTypeHandler(leaveType, leaveTypeKey) {
    setLeaveType(leaveType);
    setLeaveTypeKey(leaveTypeKey);
    if (leaveType === 'Unpaid Leave') {
      return;
    }
    const currentLeaveBalance = await getLeaveBalance(displayName, leaveType, leaveTypeKey);
    const tempLeavesArray = [].concat(leaveDates);
    setleaveCount(calcLeaveCount(tempLeavesArray, leaveType));
    if (currentLeaveBalance < calcLeaveCount(tempLeavesArray, leaveType)) {
      setLeaveDates(['']);
      setleaveCount(calcLeaveCount([''], leaveType));
      await getLeaveBalance(displayName, leaveType, leaveTypeKey);
      alert(`Not enough leaves left. You only have ${currentLeaveBalance} leaves left.`);
      return;
    }
  }
  ///// end of Event Handlers ////

  ///// Event Input Listeners ////
  function reasonListener(event) {
    setReason(event.target.value);
  }

  function isWholedayListener(event) {
    setIsWholeday(event.target.checked);
    setIsHalfday(false);
    setIsMultiday(false);
    // Reset Leave Dates to only 1 item if this radio button is clicked
    if (leaveDates.length > 1) {
      const tempLeavesArray = [].concat(leaveDates);
      tempLeavesArray.splice(1,leaveDates.length);
      setLeaveDates(tempLeavesArray);
    } else if (leaveDates[0] !== '') {
      const count = isSaturday(leaveDates[0]) ? 0.5 : 1;
      setleaveCount(count);
    }
  }

  function isHalfdayListener(event) {
    setIsHalfday(event.target.checked);
    setIsWholeday(false);
    setIsMultiday(false);
    // Reset Leave Dates to only 1 item if this radio button is clicked
    if (leaveDates.length > 1) {
      const tempLeavesArray = [].concat(leaveDates);
      tempLeavesArray.splice(1,leaveDates.length);
      setLeaveDates(tempLeavesArray);
      setleaveCount(0.5);
    } else if (leaveDates[0] !== '') {
      setleaveCount(0.5);
    }
  }

  function isMultidayListener(event) {
    setIsMultiday(event.target.checked);
    setIsWholeday(false);
    setIsHalfday(false);
    setleaveCount(calcLeaveCount(leaveDates), leaveType);
    if (leaveDates[0] !== '') {
      const count = isSaturday(leaveDates[0]) ? 0.5 : 1;
      setleaveCount(count);
    }
  }

  async function leaveDatesListener(leaveDate, index) {
    // Check if chosen date is a sunday, if true, throw error and reset input.
    if (isSunday(leaveDate)) {
      alert('Filing a leave on sunday is not allowed.');
      leaveDate = '';
      return;
    }
    // Check if chosen date is older than today, if true, throw error and reset input.
    if (isOlderThanToday(leaveDate)) {
      alert('Filing a leave older than today is not allowed.');
      leaveDate = '';
      return;
    }
    const tempLeavesArray = [].concat(leaveDates);
    // Check if chosen date already exists, if true, throw error and reset input.
    if (isLeaveDateAlreadyExists(tempLeavesArray, leaveDate)) {
      alert('Leave date already filed.');
      leaveDate = '';
      return;
    }
    // Check if chosen date has a different year than the rest, if true, throw error and reset input.
    if (isNotThisYear(leaveDate)) {
      alert('Leaves must be in this year.');
      leaveDate = '';
      return;
    }
    // Add new date first before calculating total leaves
    tempLeavesArray[index] = leaveDate;
    if (leaveBalance < calcLeaveCount(tempLeavesArray, leaveType)) {
        alert(`Not enough leaves left. You only have ${leaveBalance} leaves left.`);
        leaveDate = '';
        return;
    }
    setLeaveDates(tempLeavesArray);
    setleaveCount(calcLeaveCount(tempLeavesArray), leaveType);
  }
  ///// end of Event Input Listeners ////

  ///// Validation functions /////
  function isSunday(leaveDate) {
    if (DateTime.fromFormat(leaveDate,'yyyy-MM-dd').weekday === 7) {
      return true;
    }
    return false;
  }
  function isSaturday(leaveDate) {
    if (DateTime.fromFormat(leaveDate,'yyyy-MM-dd').weekday === 6) {
      return true;
    }
    return false;
  }
  function isOlderThanToday(leaveDate) {
    if (DateTime.fromFormat(leaveDate,'yyyy-MM-dd').startOf('day') < DateTime.local().startOf("day")) {
      return true;
    }
    return false;
  }
  function isLeaveDateAlreadyExists(tempLeavesArray, leaveDate) {
    return tempLeavesArray.find((leaveDateItem) => {
      if (leaveDateItem === leaveDate) {
        return true;
      } else {
        return false;
      }
    });
  }
  function isNotThisYear(leaveDate) {
    if (DateTime.local().year !== DateTime.fromFormat(leaveDate,'yyyy-MM-dd').year) {
      return true;
    } else {
      return false;
    }
  }
  ///////// end of validation functions //////

  ///// Leave Calculation /////
  function calcLeaveCount(leaveDates, leaveType) {
    let leaveCount = 0;
    if (leaveType === 'Unpaid Leave') {
      return leaveCount;
    }
    for (const leaveDate of leaveDates) {
      // Check if saturday or halfday
      if (isSaturday(leaveDate) ||
          (isHalfday && leaveDate !== ''))
      {
        leaveCount += 0.5;
      } else if (leaveDate === '') {
        leaveCount += 0;
      } else {
        leaveCount += 1;
      }
    }
    return leaveCount;
  }

  async function getLeaveBalance(displayName, leaveType, leaveTypeKey) {
    // Get year of leave date to determine balance remaining.
    let leaveBalance = 0;
    const year = DateTime.local().year.toString();
    const employeesRef = doc(db, "employees", displayName);
    const empDocSnap = await getDoc(employeesRef);
    if (empDocSnap.exists()) {
      // Check if user has leaves configured
      if (empDocSnap.data()[leaveTypeKey]) {
        let leaveCredits = 0;
        const leaveExistsCheck = empDocSnap.data()[leaveTypeKey].find((leaveItem) => {
          if (leaveItem.year === year) {
            return true;
          } else {
            return false;
          }
        });
        if (!leaveExistsCheck) {
          alert(`No leave credits configured for the year ${year}, please ask HR for assistance.`);
          return 0;
        }
        empDocSnap.data()[leaveTypeKey].forEach((leaveItem) => {
          if (leaveItem.year === year) {
            leaveCredits = leaveItem.credits;
          }
        });
        const leavesRef = doc(db, "leaves", displayName);
        const leaveDocSnap = await getDoc(leavesRef);
        // Iterate through existing leaves in leaves collection
        if (leaveDocSnap.exists()) {
          const leave = leaveDocSnap.data();
          // Iterate through each datfiled based on year
          for (const key of Object.keys(leave)) {
            // Iterate through leavecount of each dateFiled
            for (const subKey of Object.keys(leave[key])) {
              // Check if leave status is approved or pending
              //   include in total if they are
              if ((leave[key][subKey].status === 'pending' ||
                  leave[key][subKey].status === 'approved') &&
                  leave[key][subKey].leaveType === leaveType) {
                    leaveBalance += leave[key][subKey].count;
              }
            }
          }
        }
        const remainingLeaves = leaveCredits - leaveBalance;
        setLeaveBalance(remainingLeaves);
        return remainingLeaves;
      } else {
        setLeaveBalance(0);
        alert(`No leave credits configured for the year ${year}, please ask HR for assistance.`);
        return 0;
      }
    }
  }
  ///// end of Leave Calculation /////


  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
    <center>
      <h4 style={{marginTop:20}}>Leaves</h4>
      <div className={classes.inputBox}>
      <Form>
        <div key={`default-radio`}>
          <Form.Check
            defaultChecked
            type='radio'
            label='&nbsp;Is this a one day leave only?'
            id='whole-day-radio'
            value={isWholeday}
            onChange={isWholedayListener}
            name='group1'
          />
          <Form.Check
            type='radio'
            label='&nbsp;Is this a half day leave only?'
            id='half-day-radio'
            value={isHalfday}
            onChange={isHalfdayListener}
            name='group1'
          />
          <Form.Check
            type='radio'
            label='&nbsp;Is this a mutliple day leave?'
            id='multi-day-radio'
            value={isMultiday}
            onChange={isMultidayListener}
            name='group1'
          />
        {
          leaveDates.map((val, index) => (
            <div key={`leavedates-${index}}`} className={classes.inputBoxRadio}>
              Leave {index+1}: &nbsp;
              <Form.Control type="date" style={{width:140}}
                onChange={(event) => {leaveDatesListener(event.target.value, index)}} onKeyDown={(e) => e.preventDefault()}
                inputMode='none' value={leaveDates[index]}>
              </Form.Control> &nbsp;
              <i className="fa-solid fa-x" onClick={() => {removeDateHandler(index)}}></i>
            </div>
          ))
        }
        { isMultiday && leaveDates.length < 10 ?
          <i className="fa-solid fa-plus" onClick={addDateHandler}></i>
          : null
        }
        </div>
        <div>
          {
            leaveType === 'Unpaid Leave'
            ? null
            : <React.Fragment>Leave Count: &nbsp; <b>{leaveCount}</b></React.Fragment>
          }
        </div>
        <div>
          {
            leaveType === 'Unpaid Leave'
            ? <React.Fragment>Remaining {leaveType} Balance: &nbsp; <b>Unlimited</b></React.Fragment>
            : <React.Fragment>Remaining {leaveType} Balance: &nbsp; <b>{leaveBalance}</b></React.Fragment>
          }
        </div>
        <div>
          <DropdownButton id="leavetype-dropdown-button" title={leaveType}>
            <Dropdown.Item key="vacation" onClick={() => {leaveTypeHandler('Vacation Leave', 'vacationLeaves')}}>Vacation Leave</Dropdown.Item>
            <Dropdown.Item key="sick" onClick={() => {leaveTypeHandler('Sick Leave', 'sickLeaves')}}>Sick Leave</Dropdown.Item>
            <Dropdown.Item key="unpaid" onClick={() => {leaveTypeHandler('Unpaid Leave', 'unpaidLeaves')}}>Unpaid leave</Dropdown.Item>
          </DropdownButton>
        </div>
        <div>
          <label htmlFor="file-upload" className={classes.customFileUpload}>
            Upload Approval Picture
          </label>
          <input id="file-upload"
            type="file"
            style={{display:'none'}}
            name="Upload Approval Picture"
            onChange={fileChangeHandler}
            accept="/image/*"
          />
        </div>
          { fileUri ? <img src={fileUri} style={{width:100, height:100}} alt='' /> : null }
        <div>
          Reason: &nbsp; <textarea style={{marginTop:10, marginBottom:10, height:55, width:200}}
            value={reason} id='reason' onChange={reasonListener}
        /> <br></br>
        <small>Reason will be reviewed by management.</small>
        <br></br>
        <Button variant='primary' onClick={fileLeaveClickHandler} style={{marginTop:10}}>File Leave</Button>
        </div>
        </Form>
      </div>

      { createResult ?
        <div className={classes.resultContainer}>
        <h3>Successfully filed the leave:</h3>
        <li><label>Date Filed: {Object.keys(createResult[DateTime.local().year])} (Today)</label></li>
        <li><label>Leave Dates: {createResult[DateTime.local().year][DateTime.local().toFormat('dd-MMM-yyyy')].leaveDates.reduce( (s,x,i) => s+(i>0 ? ', ' : '') + (x==null ? '' : x), '') } </label></li>
        <li><label>Leave Count: {createResult[DateTime.local().year][DateTime.local().toFormat('dd-MMM-yyyy')].count} days</label></li>
        <li><label>Reason: {createResult[DateTime.local().year][DateTime.local().toFormat('dd-MMM-yyyy')].reason} </label></li>
        <li><label>Leave Type: {createResult[DateTime.local().year][DateTime.local().toFormat('dd-MMM-yyyy')].leaveType} </label></li>
        <li><label>Status: {createResult[DateTime.local().year][DateTime.local().toFormat('dd-MMM-yyyy')].status} </label></li>
        <h5>Please follow up with HR<br></br>
        for leave approval.</h5>
        </div> : null }
    </center>
    </div>
    </CustomLoader>
  );
};

export default FileLeaves;
