import { useState, useEffect } from 'react';
import classes from './ManageAdmins.module.css';
import MainHeader from '../../components/MainHeader';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import CustomLoader from '../../components/CustomLoader';
import wcmatch from 'wildcard-match';
import { firebaseObserver, functions } from '../../firebaseConfig';
import ManageAdminModal from '../../components/ManageAdminModal';
import { httpsCallable } from 'firebase/functions';


const ManageAdmins = () => {

  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Display Name input form listener
  const [displayName, setDisplayName] = useState('');
  // User list state
  const [userList, setUserList] = useState([]);
  // User list state
  const [origUserList, setOrigUserList] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authSuperAdminClaim', superAdminClaim => {
      if (!superAdminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    listAllUsers();
    return () => { firebaseObserver.unsubscribe('authSuperAdminClaim'); }
  }, [navigate]);

  function displayNameListener(event) {
    setDisplayName(event.target.value);
    searchDisplayName(event.target.value);
  }

  async function listAllUsers() {
    // Enable loader when request is being sent.
    setLoaderState(true);
    const listFirebaseAuthUsers = httpsCallable(functions, 'listFirebaseAuthUsers');
    await listFirebaseAuthUsers({
      admin: true,
      superadmin: true,
      operator: true,
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
        } else {
          // Action to trigger after success.
          setUserList(response.data);
          setOrigUserList(response.data);
        }
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  // Sort files Ascending order
  function sortItemsAsc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Sort files Descending order
  function sortItemsDesc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => b.displayName > a.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Search Users
  function searchDisplayName(displayName) {
    const isMatch = wcmatch(`*${displayName}*`);
    const myData = [];
    origUserList.forEach((user) => {
      if (user.labelDisplayName && isMatch(user.labelDisplayName)) {
        myData.push(user);
      }
      else if (isMatch(user.displayName)) {
        myData.push(user);
      }
    });
    setUserList(myData);
  }

  // Props callback function for parent props
  function getConfirmation(isSuccess) {
    if (isSuccess){
      listAllUsers();
    }
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}> <MainHeader />
      <center>
        <h4 style={{marginTop:20}}>Manage Admins</h4>
        <div style={{display:'inline-block'}}>
          <label htmlFor='displayName'>Display Username:</label> &nbsp;
          <input type='displayName' value={displayName} id='displayName' onChange={displayNameListener} />
          <br></br>
          <Button
            variant='primary'
            type='button'
            style={{marginTop:10}}
            className={classes.toggle}
            onClick={() => sortItemsAsc()}
          >
            Sort Up
          </Button> &nbsp;
          <Button
            variant='primary'
            type='button'
            style={{marginTop:10}}
            className={classes.toggle}
            onClick={() => sortItemsDesc()}
          >
            Sort Down
          </Button>
        </div>
        <br></br>
        <div className={classes.viewBox}>
        <ul className={classes.tablestyle}>
        {
          userList.map((val) => (
            <div key={val.displayName} style={{marginTop:10}}>
            <label>
            <ManageAdminModal
              uid={val.uid}
              details={val}
              displayName={val.labelDisplayName || val.displayName}
              onConfirmation={getConfirmation}
            />
            &nbsp;
            { val.superadmin ? <b style={{color:'blue'}}> SuperAdmin</b> : null }
            { val.admin && !val.superadmin ? <b> Admin</b> : null }
            { val.operator && !val.superadmin ? <b> Operator</b> : null }
            </label>
            </div>
          ))
        }
        </ul>
        </div>
      </center>
    </div>
    </CustomLoader>
  );
};

export default ManageAdmins;
