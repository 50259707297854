import { db } from "../firebaseConfig";
import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore/lite";
import classes from "./ViewTimeInOut.module.css";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import ImagePopUpModal from "./ImagePopUpModal";


const ViewTimeInOut = (props) => {

  // state for getting list of screenshots
  const [timeInOutList, setTimeInOutList] = useState([]);
  // state for getting list of screenshots
  const [dateFilter, setDateFilter] = useState();
  // state for getting list of screenshots
  const [filteredList, setFilteredList] = useState([]);
  // state for 3rd panel for timein out details
  const [timeInOutDetails, setTimeInOutDetails] = useState();
  // Get window size on screen resize
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  // List All TimeIn/Out records for the user group into users
  async function listItems(displayName) {
    setTimeInOutList([]);
    const timekeepingRef = doc(db, "timekeeping", displayName);
    const timekeepingSnapshot = await getDoc(timekeepingRef);
    const timeInOutRecords = [];

    // Build array of object, add monthYear object Key
    const timekeepingArray = [];
    for (const timekeepingKeyDay of Object.keys(timekeepingSnapshot.data())) {
      // Generate monthYear Object key for grouping daily timeInOut records
      const monthYear = timekeepingKeyDay.split("-")[1].concat('-').concat(timekeepingKeyDay.split("-")[2]);
      const dateRecord = {
        monthYear: monthYear,
        date: timekeepingKeyDay,
        timeIn: timekeepingSnapshot.data()[timekeepingKeyDay].timeIn,
        timeInCoords: timekeepingSnapshot.data()[timekeepingKeyDay].timeInCoords,
        timeInImagePath: timekeepingSnapshot.data()[timekeepingKeyDay].timeInImagePath,
        timeInImageUrl: timekeepingSnapshot.data()[timekeepingKeyDay].timeInImageUrl,
        timeOut: timekeepingSnapshot.data()[timekeepingKeyDay].timeOut,
        timeOutCoords: timekeepingSnapshot.data()[timekeepingKeyDay].timeOutCoords,
        timeOutImagePath: timekeepingSnapshot.data()[timekeepingKeyDay].timeOutImagePath,
        timeOutImageUrl: timekeepingSnapshot.data()[timekeepingKeyDay].timeOutImageUrl,
        shiftStart: timekeepingSnapshot.data()[timekeepingKeyDay].shiftStart,
        isLate: timekeepingSnapshot.data()[timekeepingKeyDay].isLate,
        lateDurationMinutes: timekeepingSnapshot.data()[timekeepingKeyDay].lateDurationMinutes,
        isOverTime: timekeepingSnapshot.data()[timekeepingKeyDay].isOverTime,
        overTimeDurationMinutes: timekeepingSnapshot.data()[timekeepingKeyDay].overTimeDurationMinutes,
        isUnderTime: timekeepingSnapshot.data()[timekeepingKeyDay].isUnderTime,
        underTimeDurationMinutes: timekeepingSnapshot.data()[timekeepingKeyDay].underTimeDurationMinutes,
        workDuration: timekeepingSnapshot.data()[timekeepingKeyDay].workDuration
      };
      timekeepingArray.push(dateRecord);
    };
    // console.log(timekeepingArray);

    // Build array of object with grouped timeInOut Records into monthYear:
    for (const timeInOutDayRecord of timekeepingArray) {
      // if first iteration, just insert timeInOutDayRecord immediately
      if (timeInOutRecords.length === 0) {
        timeInOutRecords.push({
          monthYear: timeInOutDayRecord.monthYear,
          dateRecord: [timeInOutDayRecord]
        });
      } else {
        // Find matching monthYear property
        // if found, push object into dateRecords property
        // else create new array object
        const findIndex = timeInOutRecords.findIndex((timeInOutItems) =>
            timeInOutItems.monthYear === timeInOutDayRecord.monthYear
        );
        // console.log(`found match ${findIndex}`);
        if (findIndex >= 0) {
            timeInOutRecords[findIndex].dateRecord.push(timeInOutDayRecord);
          } else {
            timeInOutRecords.push({
              monthYear: timeInOutDayRecord.monthYear,
              dateRecord: [timeInOutDayRecord]
            });
          }
      }
    }
    // console.log(timeInOutRecords);
    setTimeInOutList(timeInOutRecords);
  }

  // Filter Screenshots based on selected date
  async function filterItems(dateFilter) {
    setDateFilter(dateFilter);
    setFilteredList([]);
    const timeInOutArray = [];
    const filtered = timeInOutList.find((timeInOutItems) => {
      if (timeInOutItems.monthYear === dateFilter) {
        return timeInOutItems;
      }
      return null;
    });
    // console.log(filtered);
    filtered.dateRecord.forEach((timeInOutItem) => {
        timeInOutArray.push(timeInOutItem);
    });
    timeInOutArray.sort((a, b) => DateTime.fromFormat(b.timeIn, 'dd-MMM-yyyy h:mm a') > DateTime.fromFormat(a.timeIn, 'dd-MMM-yyyy h:mm a') ? 1 : -1);
    setFilteredList(timeInOutArray);

  }


  return (
    <div>
      <center>
      <div className={classes.mainContainer}>
      { dateFilter || windowSize.innerWidth < 900 ? null : <div className={classes.hiddenContainerItems}></div> }
      <div className={classes.containerMainItems}>
      <h5>{props.displayName}</h5> &nbsp;
      <Button variant="primary" onClick={() => listItems(props.displayName)}>Show List</Button> &nbsp;
      <ul id="timeInOutList" className={classes.tablestyle}>
        {
            timeInOutList.map((val) => (
            <li key={val.monthYear}>
              <Button variant="primary" onClick={()=>filterItems(val.monthYear)}>
                {val.monthYear}
              </Button>
            </li>
          ))
        }
      </ul>
      </div>
      { dateFilter ? <div className={classes.containerItems}>
        <h3>{dateFilter}</h3>
        { dateFilter ? <ul id="folderResults" className={classes.tablestyleMid}>
        {
          filteredList.map((timeInOut) => (
            <li key={timeInOut.timeIn}
              onMouseEnter={() => {
                setTimeInOutDetails(timeInOut);
              }}
            >
              <ImagePopUpModal key={timeInOut.timeIn}
                imgUrlIcon={timeInOut.timeInImageUrl}
                imgUrl={timeInOut.timeInImageUrl}
                timestamp={timeInOut.timeIn}
              />
              &nbsp;
              <ImagePopUpModal key={timeInOut.timeOut}
                imgUrlIcon={timeInOut.timeOutImageUrl}
                imgUrl={timeInOut.timeOutImageUrl}
                timestamp={timeInOut.timeOut}
              />
            </li>
          ))
        }
        </ul> : null}
      </div> : null}
      { timeInOutDetails ? <div className={classes.containerMainItems}>
        <h3>Details</h3>
        { timeInOutDetails.shiftStart !== null && timeInOutDetails.shiftStart !== undefined
          ? <b style={{color:"blue"}}>Shift Start: {timeInOutDetails.shiftStart}</b>
          : <b style={{color:"red"}}>NO START SHIFT CONFIGURED</b>
        }
        { timeInOutDetails.isLate !== null && timeInOutDetails.isLate !== undefined
          ? <React.Fragment>
            {timeInOutDetails.isLate ? <b style={{color:"red"}}>LATE</b> : <b style={{color:"blue"}}>NOT LATE</b>}
            {timeInOutDetails.isLate ? <b style={{color:"red"}}>{timeInOutDetails.lateDurationMinutes} minutes late</b> : null}
            </React.Fragment>
          : null
        }
        { timeInOutDetails.isOverTime !== null && timeInOutDetails.isOverTime !== undefined
          ? <React.Fragment>
            {timeInOutDetails.isOverTime ? <b style={{color:"green"}}>Overtime found</b> : null}
            {timeInOutDetails.isOverTime ? <b style={{color:"green"}}>{timeInOutDetails.overTimeDurationMinutes} overtime minutes</b> : null}
            </React.Fragment>
          : null
        }
        { timeInOutDetails.isUnderTime !== null && timeInOutDetails.isUnderTime !== undefined
          ? <React.Fragment>
            {timeInOutDetails.isUnderTime ? <b style={{color:"red"}}>Undertime found</b> : null}
            {timeInOutDetails.isUnderTime ? <b style={{color:"red"}}>{timeInOutDetails.underTimeDurationMinutes} undertime minutes</b> : null}
            </React.Fragment>
          : null
        }
        <b>Time In:</b> {timeInOutDetails.timeIn}
        <br></br>
        { timeInOutDetails.timeOut
          ? <React.Fragment><b>Time Out:</b> {timeInOutDetails.timeOut}
              <br></br>
            </React.Fragment>
          : null
        }
        { timeInOutDetails.workDuration
          ? <React.Fragment><b>Work Duration:</b>
            {timeInOutDetails.workDuration.hours} hours
            <br></br>
            {timeInOutDetails.workDuration.minutes} minutes
            <br></br>
            </React.Fragment>
          : null
        }
      </div> : null}
      </div>
      </center>
    </div>
  );
}

export default ViewTimeInOut;
