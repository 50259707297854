import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const SetTimeInPinModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Time-in Pin input
  const [timeInPin, setTimeInPin] = useState('');
  // Input Type toggle
  const [inputTypeToggle, setInputTypeToggle] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function validatePin(timeInPin) {
    if (isNaN(timeInPin)) {
      alert(`Pin must only contain numbers.`);
      return false;
    }
    if (timeInPin.length < 6 || timeInPin.length > 10) {
      alert(`Pin must be between 6 - 10 digits.`);
      return false;
    }
    return true
  }

  async function saveHandler() {
    if (!validatePin(timeInPin)) {
      return;
    }
    setLoaderState(true);
    await setTimeInPinFunction(props.displayName, timeInPin);
    setInputTypeToggle(false);
    setTimeInPin('');
    props.onSaveConfirmation(true);
    setShow(false);
  }

  function cancelHandler() {
    setInputTypeToggle(false);
    setTimeInPin('');
    setShow(false);
  }

  function toggleMaskPinHandler() {
    setInputTypeToggle(!inputTypeToggle);
  }

  function timeInPinListener(event) {
    setTimeInPin(event.target.value);
  }

  async function setTimeInPinFunction(displayName, timeInPin) {
    const setTimeInPin = httpsCallable(functions, 'setTimeInPin');
    await setTimeInPin({
      displayName: displayName,
      timeInPin: timeInPin
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        // Check if errors are encountered.
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
          return '';
        }
        // return back success with removal of displayName from the list
        // props.onDeleteConfirmation(true, props.displayName);
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Change My Time-in Pin
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Time-in Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Set your time-in pin.</h5>
          <b>Pin must be between 6-10 and contain only numbers.</b><br></br>
          <b>Time in Pin:</b> &nbsp;
          <input type={inputTypeToggle ? 'text' : 'password' } value={timeInPin} id='timeInPin' onChange={timeInPinListener} /> &nbsp;
          {
            inputTypeToggle ?
            <i className="fa fa-eye" aria-hidden="true" onClick={toggleMaskPinHandler}></i>
            :
            <i className="fa fa-eye-slash" aria-hidden="true" onClick={toggleMaskPinHandler}></i>
          }
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div>
          <Button variant="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
            &nbsp;
          <Button variant="primary" onClick={saveHandler}>
            Save
          </Button>
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SetTimeInPinModal

