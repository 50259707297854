import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../../components/MainHeader";
import { db } from "../../firebaseConfig";
import classes from "./AuditDetails.module.css";
import SimpleMap from "../../components/SimpleMap";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import { doc, getDoc } from "firebase/firestore/lite";
import { DateTime } from "luxon";

const AuditDetails = () => {

  // state for getting list of folders
  const [imageList, setImageList] = useState([]);
  // state for setting dateFilter
  const [dateFilter, setDateFilter] = useState('');
  // state for getting list of images
  const [filteredImages, setFilteredImages] = useState([]);
  // state for getting coordinate to show gmap component
  const [gMapCoords, setGMapCoords] = useState();
  // Get data from previous route/page
  const location = useLocation();
  // Get window size on screen resize
  const [windowSize, setWindowSize] = useState(getWindowSize());

  async function filterImages(dateFilter) {
    let mapCoordinates = {};
    // Coordinates schema
    // const mapCoordinates = {
    //   center: {
    //     lat: 59.955413,
    //     lng: 30.337844
    //   },
    //   zoom: 12,
    //   markers:[
    //     {lat:59.955413, lng:30.337844}
    //   ]
    // };
    // Requery GPS coordinates
    mapCoordinates.center = { lat:Number(process.env.REACT_APP_CENTER_LAT), lng:Number(process.env.REACT_APP_CENTER_LNG) };
    mapCoordinates.zoom = 5;
    mapCoordinates.markers = [];
    const screenshotArray = [];
    const filteredDateImageList = imageList.find((imageItems) => {
      if (imageItems.date === dateFilter) {
        return imageItems[dateFilter];
      }
      return null;
    });

    filteredDateImageList[dateFilter].forEach((item) => {
      screenshotArray.push({url:`${item.imageUrl}`,
        timestamp:`${item.timestamp}`,
        lat:`${item.latitude}`,
        lng:`${item.longitude}`
      })
    });
    screenshotArray.sort((a, b) => DateTime.fromFormat(b.timestamp, 'dd-MMM-yyyy h:mm a') > DateTime.fromFormat(a.timestamp, 'dd-MMM-yyyy h:mm a') ? 1 : -1);
    setFilteredImages(screenshotArray);
    setGMapCoords(mapCoordinates);

    // await listAll(storageRef)
    //   .then((res) => {
    //     res.items.forEach((item) => {
    //       getMetadata(ref(storage, `${storageRef}/${item.name}`)).then((metadata) => {
    //         setFilteredImages(arr =>
    //           [...arr, {url:`${metadata.customMetadata.downloadUrl}`,
    //                     timestamp:`${metadata.customMetadata.timestamp}`,
    //                     lat:`${metadata.customMetadata.latitude}`,lng:`${metadata.customMetadata.longitude}` }
    //           ]);
    //       })
    //     });
    //   })
    //   .catch(err => {
    //     alert(err.message);
    //   });

      // console.log(mapCoordinates);
      // console.log(gMapCoords);
      // return mapCoordinates;
  }

  useEffect(() => {
    if (imageList.length === 0) {
      listAllImages();
    }

    async function listAllImages() {
      const screenshotsRef = doc(db, "screenshots", location.state);
      const screenshotSnapshot = await getDoc(screenshotsRef);
      const screenshotArray = [];
      for (const screenshotKeyDay of Object.keys(screenshotSnapshot.data())) {
        screenshotArray.push({date: screenshotKeyDay, [screenshotKeyDay]: screenshotSnapshot.data()[screenshotKeyDay]});
      }
      screenshotArray.sort((a, b) => DateTime.fromFormat(b.date, 'dd-MMM-yyyy') > DateTime.fromFormat(a.date, 'dd-MMM-yyyy') ? 1 : -1);
      setImageList(screenshotArray);

      // const storageRef = ref(storage,`screenshots/${location.state}`);
      // await listAll(storageRef)
      //   .then((res) => {
      //     res.prefixes.forEach((folderRef) => {
      //       setImageList(arr => [...arr, `${folderRef.name}`]);
      //     });
      //   })
      //   .catch(err => {
      //     alert(err.message);
      //   });
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[imageList.length, location.state]);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
    <div>
    <MainHeader />
      <center>
      <div className={classes.mainContainer}>
      { dateFilter || windowSize.innerWidth < 900 ? null : <div className={classes.hiddenContainerItems}></div> }
        <div className={classes.containerItems}>
        <h3>{location.state}</h3>
        <ul id="folderResults" className={classes.tablestyle}>
        {
          imageList.map((val) => (
            <li key={val.date} onClick={() => {
              setFilteredImages([]);
              setDateFilter(val.date);
              filterImages(val.date);
              }
            }>
              {val.date}
            </li>
          ))
        }
        </ul>
        </div>
        { dateFilter ? <div className={classes.containerItems}>
          <h3>{dateFilter}</h3>
          { filteredImages ? <ul id="folderResults" className={classes.tablestyle}>
          {
            filteredImages.map((image) => (
              <li key={image.url} style={{padding:0, border:0}}
                onMouseEnter={() => {
                setGMapCoords();
                setGMapCoords({ markers:[{lat:image.lat, lng:image.lng}], center:{lat:Number(process.env.REACT_APP_CENTER_LAT), lng:Number(process.env.REACT_APP_CENTER_LNG)}, zoom:5 });
                }}
              >
                <ImagePopUpModal key={image.timestamp}
                  imgUrlIcon={image.url}
                  imgUrl={image.url}
                  timestamp={image.timestamp}
                />
              </li>
            ))
          }
          </ul> : null}
        </div> : null}
        { gMapCoords ? <div className={classes.containerItems}>
          <SimpleMap center={gMapCoords.center} zoom={gMapCoords.zoom} markers={gMapCoords.markers}
            width='430px' height='300px' />
        </div> : null}
      <br></br>
      </div>
      </center>
    </div>
    );
};

export default AuditDetails;


// {
//   data.map((image) => (
//     <a key={image.url} href={image.url} target="popup" rel="noreferrer"
//       onMouseEnter={() => {
//         setGMapCoords();
//         setGMapCoords({ markers:[{lat:image.lat, lng:image.lng}], center:{lat:14.616302,lng:121.068039}, zoom:11 });
//       }}>
//     <li>
//       <img src={image.url} width="50" height="50" alt=""></img>
//       &nbsp; {image.timestamp}
//     </li>
//     </a>

//   ))
// }
