import MainHeader from '../../components/MainHeader';
import { Link, useNavigate } from 'react-router-dom';
import classes from './AuditUploads.module.css';
import { useEffect, useState } from 'react';
import { firebaseObserver, db } from "../../firebaseConfig";
import wcmatch from 'wildcard-match';
import Button from 'react-bootstrap/Button';
import { collection, getDocs } from "firebase/firestore/lite";
import { DateTime } from "luxon";


const AuditUploads = () => {

  // States for storing name search results
  const [nameList, setNameList] = useState([]);
  // Name input form listener
  const [nameSearch, setNameSearch] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authAdminClaim', adminClaim => {
      if (!adminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    return () => { firebaseObserver.unsubscribe('authAdminClaim'); }
  }, [navigate]);

  function nameChangeListener(event) {
    setNameSearch(event.target.value);
  }

  // triggering search when enter is pressed
  function enterKeySearch(event) {
    if (event.key === 'Enter') {
      searchItems();
    }
  }

  // triggering search when button is pressed
  async function searchItems() {
    const isMatch = wcmatch(`*${nameSearch}*`);
    setNameList([]);
    const screenshotsRef = collection(db, "screenshots");
    const screenshotSnapshot = await getDocs(screenshotsRef);
    screenshotSnapshot.forEach((doc) => {
      if (isMatch(doc.id)) {
        setNameList(arr => [...arr, `${doc.id}`]);
      }
    });
  }

  async function listAllUsers() {
    setNameList([]);
    const screenshotsRef = collection(db, "screenshots");
    const screenshotSnapshot = await getDocs(screenshotsRef);
    const userArray = [];
    screenshotSnapshot.forEach((doc) => {
      const datePropertiesArray = [];
      let maxDate;
      for (const dateKey in doc.data()) {
        datePropertiesArray.push(DateTime.fromFormat(dateKey, 'dd-MMM-yyyy'));
      }
      if (datePropertiesArray.length >= 1){
        maxDate = DateTime.max.apply(null, datePropertiesArray);
      }
      userArray.push({displayName: doc.id, maxDate: maxDate});
    });
    userArray.sort((a, b) => b.maxDate > a.maxDate ? 1 : -1);
    setNameList(userArray);
    // const storageRef = ref(storage,`screenshots/`);
    // listAll(storageRef)
    //   .then((res) => {
    //     res.prefixes.forEach((folderRef) => {
    //       setNameList(arr => [...arr, `${folderRef.name}`]);
    //     })
    //   })
    //   .catch(err => {
    //     alert(err.message);
    //   });
  }

  function sortItemsAsc() {
    const myData = [].concat(nameList)
    myData.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    setNameList(myData);
  }

  function sortItemsDesc() {
    const myData = [].concat(nameList)
    myData.sort((a, b) => b.displayName > a.displayName ? 1 : -1);
    setNameList(myData);
  }

  return (
    <div className={classes.overlayWrapper}><MainHeader />
      <div>
      <center>
        <div className={classes.searchBox}>
          <label htmlFor='text'>Display Name: </label> &nbsp;
          <input style={{ marginBottom:20}} type='text' id='displayName' onChange={nameChangeListener} onKeyDown={enterKeySearch} required />&nbsp;
          <Button variant="primary" onClick={searchItems}>Search User</Button>
          <br></br>
          <br></br>
          <Button variant="primary" onClick={listAllUsers}>List All Users</Button> &nbsp;
          <Button variant="primary" onClick={sortItemsAsc}>Sort Up</Button> &nbsp;
          <Button variant="primary" onClick={sortItemsDesc}>Sort Down</Button> &nbsp;
          <ul id="searchResults" className={classes.tablestyle}>
          {
            nameList.map((val) => (
              <Link key={val.displayName} to="/audituploads/details" state={val.displayName}>
                <li>
                  {val.displayName}
                </li>
              </Link>
            ))
          }
          </ul>
        </div>
      </center>
      <br></br>
      </div>
    </div>
  );
};

export default AuditUploads;
