import { firebaseObserver } from "../../firebaseConfig";
import { useEffect } from "react";
import MainHeader from "../../components/MainHeader";
import { useNavigate } from "react-router-dom";
import ViewTimeInOut from "../../components/ViewTimeInOut";
import { useLocation } from "react-router-dom";


const AuditTimeInOutDetails = () => {

  // Get data from previous route/page
  const location = useLocation();

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    firebaseObserver.subscribe('authAdminClaim', adminClaim => {
      if (!adminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    return () => { firebaseObserver.unsubscribe('authAdminClaim'); }
  },[]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  return (
    <div>
    <MainHeader />
      <center>
        <ViewTimeInOut displayName={location.state}/>
      </center>
    </div>
  );
}

export default AuditTimeInOutDetails;
