import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { db } from "../firebaseConfig";
import { collection, setDoc, doc } from 'firebase/firestore/lite';

const EditBroadcastModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // New Message state
  const [newMessage, setNewMessage] = useState(props.message);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function yesHandler() {
    setLoaderState(true);
    await editMessage(props.id, newMessage);
    props.onEditConfirmation(true, props.id);
    setShow(false);
  }

  function noHandler() {
    setShow(false)
  }

  async function editMessage(id, editedMessage) {
    // Enable loader when request is being sent.
    setLoaderState(true);
    const broadcastsRef = collection(db, "broadcasts");
    await setDoc(doc(broadcastsRef, id),
      { message: editedMessage }
    );
    setLoaderState(false);
  }

  function newMessageListener(event) {
    setNewMessage(event.target.value);
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Edit
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Broadcast Message</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{alignItems:'baseline'}}>
          <div> <b>Date:</b> {props.id}</div>
          <div style={{display:'flex', alignItems:'center'}}> <b>Message:</b> &nbsp;
            <textarea style={{marginTop:10, marginBottom:10, height:55, width:300}}
            value={newMessage} id='message' onChange={newMessageListener} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div>
          <Button variant="secondary" onClick={noHandler}>
            No
          </Button> &nbsp;
          <Button variant="primary" onClick={yesHandler}>
            Yes
          </Button>
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditBroadcastModal


