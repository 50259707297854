import { firebaseObserver } from "../../firebaseConfig";
import { useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import classes from "./ViewScreenshotsV2.module.css";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import { useAuthContext } from "../../components/context/useAuthContext";
import SimpleMap from "../../components/SimpleMap";
import { searchDocuments } from "../../components/database/mainColl/search";


const ViewScreenshotsV2 = () => {

  // User Auth Context
  const { user } = useAuthContext();
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);
  // state for getting list of screenshots
  const [screenshotList, setScreenshotList] = useState([]);
  // state for getting list of screenshots
  const [dateFilter, setDateFilter] = useState();
  // state for getting list of screenshots
  const [filteredList, setFilteredList] = useState();
  // state for getting coordinate to show gmap component
  const [gMapCoords, setGMapCoords] = useState();
  // Get window size on screen resize
  const [windowSize, setWindowSize] = useState(getWindowSize());

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      firebaseObserver.unsubscribe('authStateChanged');
    };
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  // List All Screenshots
  async function listItems() {
    setScreenshotList([]);
    const screenshotSnapshot = await searchDocuments(
      "agentScreenshots", 
      [{key: "displayName", inputValue: displayName}],
      100,
      "timestamp",
      "desc"
    );
    const screenshotArray = [];
    for (const screenshot of screenshotSnapshot.docs) {
        const existingDateItem = screenshotArray.find((transformedScreenshot) => 
          transformedScreenshot.date === screenshot.timestamp.split(' ')[0]
        );
        if (existingDateItem) {
          existingDateItem.screenshots.push(screenshot);
        } else {
          screenshotArray.push({ date: screenshot.timestamp.split(' ')[0], screenshots: [screenshot] });
        }
    };
    screenshotArray.sort((a, b) => DateTime.fromFormat(b.date, 'dd-MMM-yyyy') > DateTime.fromFormat(a.date, 'dd-MMM-yyyy') ? 1 : -1);
    setScreenshotList(screenshotArray);
  }

  // Filter Screenshots based on selected date
  async function filterItems(dateFilter) {
    setDateFilter(dateFilter);
    const filtered = screenshotList.find((screenshotItems) => {
      if (screenshotItems.date === dateFilter) {
        return screenshotItems;
      }
      return null;
    });
    setFilteredList(filtered);
    const mapCoordinates = {};
    mapCoordinates.center = { lat:Number(process.env.REACT_APP_CENTER_LAT), lng:Number(process.env.REACT_APP_CENTER_LNG) };
    mapCoordinates.zoom = 5;
    mapCoordinates.markers = [];
    setGMapCoords(mapCoordinates);
  }


  return (
    <div>
    <MainHeader />
      <center>
      <div className={classes.mainContainer}>
      { dateFilter || windowSize.innerWidth < 900 ? null : <div className={classes.hiddenContainerItems}></div> }
      <div className={classes.containerMainItems}>
      <Button variant="primary" onClick={listItems}>Show List</Button> &nbsp;
      <ul id="screenshots" className={classes.tablestyle}>
        {
          screenshotList.map((val) => (
            <li key={val.date}>
              <Button variant="primary" onClick={()=>filterItems(val.date)}>
                {val.date}
              </Button>
            </li>
          ))
        }
      </ul>
      </div>
      { dateFilter ? <div className={classes.containerItems}>
        <h3>{dateFilter}</h3>
        { dateFilter ? <ul id="folderResults" className={classes.tablestyle}>
        {
          filteredList.screenshots.map((image) => (
            <li key={image.imageUrl} style={{padding:0, border:0}}
              onMouseEnter={() => {
              setGMapCoords();
              setGMapCoords({ markers:[{lat:image.latitude, lng:image.longitude}], center:{lat:Number(process.env.REACT_APP_CENTER_LAT), lng:Number(process.env.REACT_APP_CENTER_LNG)}, zoom:5 });
              }}
            >
              <ImagePopUpModal key={image.timestamp}
                imgUrlIcon={image.imageUrl}
                imgUrl={image.imageUrl}
                timestamp={image.timestamp}
              />
            </li>
          ))
        }
        </ul> : null}
      </div> : null}
      { gMapCoords ? <div className={classes.containerItems}>
        <SimpleMap center={gMapCoords.center} zoom={gMapCoords.zoom} markers={gMapCoords.markers}
          width='350px' height='300px' />
      </div> : null}
      </div>
      </center>
    </div>
  );
}

export default ViewScreenshotsV2;
