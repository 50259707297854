import MainHeader from '../../components/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from "./ManageBroadcasts.module.css";
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import CustomLoader from "../../components/CustomLoader";
import { db, firebaseObserver } from "../../firebaseConfig";
import { collection, getDocs, setDoc, doc } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import DeleteBroadcastModal from '../../components/DeleteBroadcastModal';
import EditBroadcastModal from '../../components/EditBroadcastModal';

const ManageBroadcasts = () => {

  // User list state
  const [messageList, setMessageList] = useState([]);
  // New Message state
  const [newMessage, setNewMessage] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authAdminClaim', adminClaim => {
      if (!adminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    listAllBroadcasts();
    return () => { firebaseObserver.unsubscribe('authAdminClaim'); }
  }, [navigate]);

  async function listAllBroadcasts() {
    // Enable loader when request is being sent.
    setMessageList([]);
    setLoaderState(true);

    const broadcastsRef = collection(db, "broadcasts");
    const snapshot = await getDocs(broadcastsRef);
    const messageArray = [];
    snapshot.forEach((doc) => {
      messageArray.push({id: doc.id, ...doc.data()});
    });
    messageArray.sort((a, b) => DateTime.fromFormat(b.id, 'dd-MMM-yyyy') > DateTime.fromFormat(a.id, 'dd-MMM-yyyy') ? 1 : -1);
    setMessageList(messageArray);
    setLoaderState(false);
  }

  async function createNewMessage() {
    if (!newMessage) {
      alert(`New broadcast message cannot be blank.`);
      return;
    }
    // Enable loader when request is being sent.
    setLoaderState(true);
    const broadcastsRef = collection(db, "broadcasts");
    const dateTime = DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy');
    try {
      await setDoc(doc(broadcastsRef, dateTime), {
        message: newMessage
      });
    } catch (error) {
      setLoaderState(false);
      alert(`Unable to write broadcasts due to error: ${error}`);
      return;
    }
    setMessageList([]);
    listAllBroadcasts();
    setLoaderState(false);
    resetInputFields();
  }
  function newMessageListener(event) {
    setNewMessage(event.target.value);
  }
  function resetInputFields() {
    setNewMessage('');
  }
  function getDeleteConfirmation(isSuccess, id) {
    if (isSuccess){
      const arr = messageList.filter(obj => obj.id !== id);
      setMessageList(arr);
      alert(`Successfully deleted message date: ${id}`);
    }
  }
  function getEditConfirmation(isSuccess, id) {
    if (isSuccess){
      listAllBroadcasts();
      alert(`Successfully edited message date: ${id}`);
    }
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>Make New Broadcast</h4>
        <div style={{display:'inline-block'}}>
          <textarea style={{marginTop:10, marginBottom:10, height:55, width:300}}
            value={newMessage} id='newMessage' onChange={newMessageListener}
          /> <br></br>
          <Button variant='primary' onClick={createNewMessage}>Create Message</Button>
        </div>
        <h4 style={{marginTop:20}}>Manage Broadcasts</h4>
        <ul className={classes.viewBox}>
        {
          messageList.map((val) => (
            <div key={val.id}>
              <li style={{display:'inline-block', justifyConte:'center', marginBottom:10, marginTop:10}}>
                <b>{val.id}:</b> <br></br>
                {val.message}
              </li> &nbsp;
              <EditBroadcastModal
                id={val.id}
                message={val.message}
                onEditConfirmation={getEditConfirmation}
              /> &nbsp;
              <DeleteBroadcastModal
                id={val.id}
                message={val.message}
                onDeleteConfirmation={getDeleteConfirmation}
              />
            </div>
          ))
        }
        </ul>
        </center>
      </div>
    </CustomLoader>
  );

};

export default ManageBroadcasts;

// <ul id="screenshots" className={classes.tablestyle}>
// {
//   userList.map((val) => (
//     <a key={val.url} href={val.url} target="popup" rel="noreferrer">
//     <li>
//       <img src={val.url} width="50" height="50" alt=""></img>
//       &nbsp; {val.timestamp}
//     </li>
//     </a>
//   ))
// }
// </ul>
