import { firebaseObserver, storage } from "../../firebaseConfig";
import React, { useEffect, useState, useRef } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  updateMetadata,
  listAll,
  deleteObject
} from "firebase/storage";
import MainHeader from "../../components/MainHeader";
import classes from "./UploadScreenshotV2.module.css";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import { DateTime } from "luxon";
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from "../../components/context/useAuthContext";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { insertDocument } from "../../components/database/mainColl/insert";


const UploadScreenshotV2 = () => {
  // User Auth Context
  const { user } = useAuthContext();
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);

  // State to store uploaded file uri for preview
  const [fileUri, setFileUri] = useState("");
  // State to store  progress
  const [percent, setPercent] = useState(0);
  // State to store uploaded file download url link
  const [url, setUploadedUrl] = useState('');

  // Get coordinates
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

  // Camera State
  const [cameraState, setCameraState] = useState(false);
  // Camera stream useRef
  const videoRef = useRef(null);
  // Camera open/close tracker (bug workaround)
  const videoFirstRender = useRef(0);
  // Camera not supported/error false = no error
  const [cameraInErrorStatus, setCameraInErrorStatus] = useState(false);


  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getImageCount(userFolder, datestamp) {
    const storageRef = ref(storage, `/screenshots/${userFolder}/${datestamp.split(" ")[0]}`)
    const itemResults = await listAll(storageRef);
    const objectCount = itemResults.items.length;
    return objectCount;
  };

  // Handle file upload event and update state
  async function handleChange(dataUri, isBase64, isManualUpload) {
    const imageFile = dataUri;
    let fileName;
    let processedFile;
    // If taken from mediaDevice camera, convert base64 to binary
    if (isBase64) {
        const fileExtension = getFileExtension(imageFile.split(',')[0]);
        fileName = uuidv4().concat('.').concat(fileExtension);
        processedFile = dataURLtoFile(imageFile, fileName);
    } else {
        processedFile = dataUri.target.files[0];
    }
    // Requery GPS coordinates
    getPosition();
    // Set timestamp
    // setTimestamp(DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy h:mm a'));
    const dateTimestamp = DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy h:mm a');
    if (processedFile) {
      setFileUri(URL.createObjectURL(processedFile));
      setPercent(0);
      setUploadedUrl();
      await handleUpload(processedFile, dateTimestamp, isBase64, isManualUpload);
    }
  }

  // Dynamic Progress to show completion icon.
  const ProgressComponent = () => {
    if (percent === 100) {
      return (<div>
        <img src={process.env.PUBLIC_URL + 'images/complete_icon.png'} alt=""></img>
        <h5>GPS coordinates</h5>
        <p> longitude: {coords.longitude} <br></br>
        latitude: {coords.latitude}</p>
      </div>)
    } else {
      return (percent + "% done");
    }
  }

  // Uploade Image display component
  const UploadedImage = () => {
    return (
      <img src={url} alt="" width="250" height="300"></img>
    )
  }

  // Action when you click upload
  async function handleUpload (file, dateTimestamp, isBase64, isManualUpload) {
    // Reset picture being uploaded.
    setUploadedUrl('');
    if (!isGeolocationEnabled) {
      alert("Reenable Geo location in your Phone.");
      window.location.reload(false);
    }
    const imagePath = `/screenshots/${displayName}/${dateTimestamp.split(" ")[0]}/${file.name}`;
    const storageRef = ref(storage, imagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      // update progress
      setPercent(percent);
      },
      (err) => alert(err.message),
      () => {
        // Get uploaded file url for perview
        getDownloadURL(uploadTask.snapshot.ref).then((urlResult) => {
          // Set download url hook
          setUploadedUrl(urlResult);
          // Update metadata to add gps coordinates to custom metadata
          const newMetadata = {
            customMetadata: {
              longitude: coords.longitude,
              latitude: coords.latitude,
              timestamp: dateTimestamp,
              downloadUrl: urlResult
            }
          };
          updateMetadata(storageRef, newMetadata)
            .then(async (metadataResult) => {
              if (!metadataResult) {
                const objectRef = ref(storageRef, file.name);
                // Delete the file
                deleteObject(objectRef).then(() => {
                  alert('Picture has no metadata. Please upload again.');
                }).catch((error) => {
                  alert(error);
                });
              } else {
                // console.log(metadataResult);
                // Write to firstore database
                const insertData = {
                  displayName: displayName,
                  timestamp: metadataResult.customMetadata.timestamp,
                  imageUrl: metadataResult.customMetadata.downloadUrl,
                  imagePath: imagePath,
                  longitude: Number(metadataResult.customMetadata.longitude),
                  latitude: Number(metadataResult.customMetadata.latitude)
                }
                try {
                  await insertDocument("agentScreenshots", insertData)
                } catch (error) {
                  alert(`Error ${error}`);
                }
              }
            }).catch((error) => {
              alert(error);
            });
        });
      }
    );

    // Reset fileURi after successful upload.
    setFileUri('');
    if (isBase64) {
      videoFirstRender.current = 1;
    } else if (isManualUpload) {
      console.log('manual');
    }
    else {
      setCameraState(false);
      console.log('SLEEP on stop');
      await sleep(10000);
      window.location.reload(false);
    }
  };

  async function validateStateVariables() {
    const dateTimestamp = DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy h:mm a');
    // Count list of image files (for checking if quota is reached)
    const fileCounter = await getImageCount(displayName, dateTimestamp);
    let cameraState = true;
    if (!isGeolocationAvailable) {
      alert("Your browser does not support Geolocation, please use Google Chrome.");
      cameraState = false;
    }
    if (!isGeolocationEnabled) {
      alert("Reenable Geo location in your Phone.");
      window.location.reload(false);
      cameraState = false;
    }
    if (fileCounter >= 10) {
      alert("You have reached Maximum file upload (10 pictures a day). Try again tomorrow.");
      cameraState = false;
    }
    setCameraState(cameraState);
  }

  async function onTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    // console.log(dataUri);
    await handleChange(dataUri, true, false);
    console.log(videoFirstRender.current);
  }
  function onCameraError (error) {
    alert('Your device does not support modern HTML5 camera features. Switching to native HTML mode.')
    setCameraInErrorStatus(true);
    console.error('onCameraError', error);
  }
  function onCameraStart (stream) {
    videoRef.current = stream;
    console.log('onCameraStart');
    setPercent(0);
    setUploadedUrl('');
  }
  async function onCameraStop () {
    console.log('onCameraStop');
    if (videoFirstRender.current === 1) {
        setCameraState(false);
        console.log('SLEEP on stop');
        await sleep(10000);
        window.location.reload(false);
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
  function getFileExtension(mime) {
    const mimeType = mime.match(/:(.*?);/)[1];
    return mimeType.split('/')[1];
  }
  async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
      <div>
        <MainHeader />
        <center>
        <div className={classes.uploadBox}>
          { cameraState
            ? <React.Fragment>
              { cameraInErrorStatus
                ? <div>
                  <label htmlFor="file-upload" className={classes.customFileUpload}>
                    Take a Picture
                  </label>
                  <input id="file-upload"
                    type="file"
                    style={{display:'none'}}
                    name="Take A picture"
                    onChange={ (event) => {handleChange(event, false, true);} }
                    accept="/image/*"
                    capture="user"
                  />
                </div>
                : <div>
                  <Camera
                  onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
                  onCameraError = { (error) => { onCameraError(error); } }
                  idealFacingMode = {FACING_MODES.USER}
                  idealResolution = {{width: 640, height: 480}}
                  imageType = {IMAGE_TYPES.JPG}
                  imageCompression = {0.97}
                  isMaxResolution = {false}
                  isImageMirror = {false}
                  isSilentMode = {true}
                  isDisplayStartCameraError = {true}
                  isFullscreen = {false}
                  sizeFactor = {1}
                  onCameraStart = { (stream) => { onCameraStart(stream); } }
                  onCameraStop = { () => { onCameraStop(); } }
                />
                </div>
              }
            </React.Fragment>
            :
            <React.Fragment>
            <Button
            variant="primary"
            onClick={validateStateVariables}
            style={{marginTop:10}}
            className={classes.buttonStyle}
            >
              Take a Picture
            </Button>
            <br></br>
            <br></br>
            <b>OR</b>
            <br></br>
            <br></br>
            <div>
              <label htmlFor="file-upload" className={classes.customFileUpload}>
                Upload Picture
              </label>
              <input id="file-upload"
                type="file"
                style={{display:'none'}}
                name="Take A picture"
                onChange={ (event) => {handleChange(event, false, true);} }
                accept="/image/*"
              />
            </div>
            </React.Fragment>
          }
          <p> {fileUri ? <img src={fileUri} style={{width:70, height:100, marginTop:15}} alt='' /> : <br></br> } </p>
          { percent ? <ProgressComponent />: null }
          { url ? <UploadedImage /> : null }
        </div>
        </center>
      </div>
  );

}

export default UploadScreenshotV2;


// return (
//   <div>
//     <MainHeader />
//     <center>
//     <div className={classes.uploadBox}>
//       <label htmlFor="file-upload" className={classes.customFileUpload}>
//         Take a Picture
//       </label>
//       <input id="file-upload"
//               type="file"
//               style={{display:'none'}}
//               name="Take A picture"
//               onChange={handleChange}
//               accept="/image/*"
//       />
//       <p> {fileUri ? <img src={fileUri} style={{width:70, height:100, marginTop:15}} alt='' /> : <br></br> } </p>
//       <p> {!isLoading ? <Button variant="primary" onClick={handleUpload} >Upload Picture</Button> : null } </p>
//       { percent ? <ProgressComponent />: null }
//       { url ? <UploadedImage /> : null }
//     </div>
//     </center>
//   </div>
// );
