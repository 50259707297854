import * as React from "react";
import MainHeader from "../../components/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./UserProfile.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import { db, auth, firebaseObserver } from "../../firebaseConfig";
import { collection, getDoc, doc } from 'firebase/firestore/lite';
import { sendPasswordResetEmail } from "firebase/auth"
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { useAuthContext } from "../../components/context/useAuthContext";
import SetTimeInPinModal from "../../components/SetTimeInPinModal";


const UserProfile = () => {
  // User Auth Context
  const { user } = useAuthContext();

  // User list state
  const [userPofile, setUserProfile] = useState();
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);
  // remaining vacation leave balance state
  const [leaveBalanceVacation, setLeaveBalanceVacation] = useState(0);
  // remaining sick leave balance state
  const [leaveBalanceSick, setLeaveBalanceSick] = useState(0);
  // Input Type toggle
  const [inputTypeToggle, setInputTypeToggle] = useState(false);

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    if (displayName) {
      getUser(displayName);
    }
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getUser(displayName) {
    // Enable loader when request is being sent.
    setLoaderState(true);

    const employeesRef = collection(db, "employees");
    const documentRef = doc(employeesRef, displayName);
    const document = await getDoc(documentRef);
    setUserProfile(document.data());
    setLeaveBalanceVacation(await getLeaveBalance(displayName, document.data(), 'Vacation Leave', 'vacationLeaves'));
    setLeaveBalanceSick(await getLeaveBalance(displayName, document.data(), 'Sick Leave', 'sickLeaves'));
    setLoaderState(false);
  }


  function resetPassword(email) {
    setLoaderState(true);
    return sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
    });
  }

  function toggleMaskPinHandler() {
    setInputTypeToggle(!inputTypeToggle);
  }

  function getSaveConfirmation(isSuccess) {
    if (isSuccess){
      getUser(displayName);
    }
  }

  async function getLeaveBalance(displayName, userDetails, leaveType, leaveTypeKey) {
    // Get year of leave date to determine balance remaining.
    let leaveBalance = 0;
    const year = DateTime.local().year.toString();
      let leaveCredits = 0;
      // Check if user has leaves configured
      if (userDetails[leaveTypeKey]) {
        const leaveExistsCheck = userDetails[leaveTypeKey].find((leaveItem) => {
          if (leaveItem.year === year) {
            return true;
          } else {
            return false;
          }
        });
        if (!leaveExistsCheck) {
          alert(`No ${leaveType} credits configured for the year ${year}, please ask HR for assistance.`);
          return 0;
        }
        userDetails[leaveTypeKey].forEach((leaveItem) => {
          if (leaveItem.year === year) {
            leaveCredits = leaveItem.credits;
          }
        });
        const leavesRef = doc(db, "leaves", displayName);
        const leaveDocSnap = await getDoc(leavesRef);
        // Iterate through existing leaves in leaves collection
        if (leaveDocSnap.exists()) {
          const leave = leaveDocSnap.data();
          // Iterate through each datfiled based on year
          for (const key of Object.keys(leave)) {
            // Iterate through leavecount of each dateFiled
            for (const subKey of Object.keys(leave[key])) {
              // Check if leave status is approved or pending
              //   include in total if they are
              if ((leave[key][subKey].status === 'pending' ||
                  leave[key][subKey].status === 'approved') &&
                  leave[key][subKey].leaveType === leaveType ) {
                    leaveBalance += leave[key][subKey].count;
              }
            }
          }
        }
        const remainingLeaves = leaveCredits - leaveBalance;
        return remainingLeaves;
      } else {
        alert(`No ${leaveType} credits configured for the year ${year}, please ask HR for assistance.`);
        return 0;
      }
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>User Profile</h4>
        <ul className={classes.viewBox}>
        { userPofile?.timeInPin ?
          <React.Fragment><b>Time-In Pin:</b> { inputTypeToggle ? userPofile.timeInPin : "******" } &nbsp;
          {
            inputTypeToggle ?
            <i className="fa fa-eye" aria-hidden="true" onClick={toggleMaskPinHandler}></i>
            :
            <i className="fa fa-eye-slash" aria-hidden="true" onClick={toggleMaskPinHandler}></i>
          }
          <br></br><br></br>
          </React.Fragment>
        : null }
        <SetTimeInPinModal
          displayName={displayName}
          onSaveConfirmation={getSaveConfirmation}
        /> <br></br><br></br>
        { userPofile ?
          <label>
            <b>Display Name:</b> {userPofile.labelDisplayName || userPofile.displayName} <br></br>
            <b>Email:</b> {userPofile.email} <br></br>
            <b>First Name:</b> {userPofile.firstName} <br></br>
            <b>Last Name:</b> {userPofile.lastName} <br></br>
            <b>Birthday:</b> {userPofile.birthday} <br></br>
            <b>Start Date:</b> {userPofile.startDate} <br></br>
            <b>Shift Start:</b> {userPofile.shiftStart} <br></br>
            <b>Vacation Leave Balance:</b> {leaveBalanceVacation} <br></br>
            <b>Sick Leave Balance:</b> {leaveBalanceSick} <br></br>
          </label>
        : null }
        <br></br>
        <br></br>
        <Button variant='primary' onClick={() => resetPassword(userPofile.email)}>
          Reset My Account Password
        </Button>
        </ul>
        </center>
      </div>
    </CustomLoader>
  );
}

export default UserProfile;
