import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { auth, functions } from '../firebaseConfig';
import { sendPasswordResetEmail } from "firebase/auth";
import { DateTime } from "luxon";
import { httpsCallable } from "firebase/functions";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const EditUserModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Input States
  const [labelDisplayName, setLabelDisplayName] = useState(props.details.labelDisplayName ? props.details.labelDisplayName : props.details.displayName);
  const [email, setEmail] = useState(props.details.email ? props.details.email : '');
  const [firstName, setFirstName] = useState(props.details.firstName ? props.details.firstName : '');
  const [lastName, setLastName] = useState(props.details.lastName ? props.details.lastName : '');
  const [phoneNumber, setPhoneNumber] = useState(props.details.phoneNumber ? props.details.phoneNumber : '');
  const [birthday, setBirthday] = useState(props.details.birthday ? props.details.birthday : '');
  const [startDate, setStartDate] = useState(props.details.startDate ? props.details.startDate : '');
  const [shiftStart, setShiftStart] = useState(props.details.shiftStart ? props.details.shiftStart : '');
  const [salary, setSalary] = useState(props.details.salary ? props.details.salary : '');
  const [vacationLeaves, setVacationLeaves] = useState(props.details.vacationLeaves ? props.details.vacationLeaves : []);
  const [sickLeaves, setSickLeaves] = useState(props.details.sickLeaves ? props.details.sickLeaves : []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Input Listeners
  function labelDisplayNameListener(event) {
    setLabelDisplayName(event.target.value);
  }
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }
  function emailListener(event) {
    setEmail(event.target.value);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }
  function birthdayListener(event) {
    setBirthday(event.target.value);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
  }
  function salaryListener(event) {
    setSalary(event.target.value);
  }
  function vacationLeavesListener(value, objectKey, arrayIndex) {
    const tempLeavesArray = [].concat(vacationLeaves);
    tempLeavesArray[arrayIndex][objectKey] = value;
    setVacationLeaves(tempLeavesArray);
  }
  function sickLeavesListener(value, objectKey, arrayIndex) {
    const tempLeavesArray = [].concat(sickLeaves);
    tempLeavesArray[arrayIndex][objectKey] = value;
    setSickLeaves(tempLeavesArray);
  }

  function addVacationLeaveHandler() {
    const tempLeavesArray = [].concat(vacationLeaves);
    tempLeavesArray.push({
      year: DateTime.local().year.toString(),
      credits: 10
    });
    // console.log(tempLeavesArray);
    setVacationLeaves(tempLeavesArray);
  }

  function addSickLeaveHandler() {
    const tempLeavesArray = [].concat(sickLeaves);
    tempLeavesArray.push({
      year: DateTime.local().year.toString(),
      credits: 10
    });
    // console.log(tempLeavesArray);
    setSickLeaves(tempLeavesArray);
  }

  function removeVacationLeaveHandler(index) {
    const tempLeavesArray = [].concat(vacationLeaves);
    tempLeavesArray.splice(index,1);
    setVacationLeaves(tempLeavesArray);
  }

  function removeSickLeaveHandler(index) {
    const tempLeavesArray = [].concat(sickLeaves);
    tempLeavesArray.splice(index,1);
    setSickLeaves(tempLeavesArray);
  }
  async function saveHandler() {
    // do not pass empty string to phone number because it will cause
    // error in firebase auth update
    const authDetails = {
      phoneNumber: phoneNumber === '' ? null : phoneNumber,
      email: email,
    };
    const dbDetails = {
      displayName: props.details.displayName,
      uid: props.details.uid,
      labelDisplayName: labelDisplayName,
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      birthday: birthday,
      startDate: startDate,
      shiftStart: shiftStart,
      salary: salary,
      vacationLeaves: vacationLeaves,
      sickLeaves: sickLeaves,
    };
    await editUser(props.details.uid, authDetails, props.details.displayName, dbDetails);
    setShow(false);
  }

  function cancelHandler() {
    setShow(false)
  }

  function selectShiftTime(shiftStartInput) {
    setShiftStart(shiftStartInput)
  }

  async function resetPassword(email) {
    setLoaderState(true);
    return await sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
      setShow(false);
    });
  }

  async function editUser(uid, authDetails, displayName, dbDetails) {
    // console.log(dbDetails);
    setLoaderState(true);
    const editUser = httpsCallable(functions, 'editUser');

    await editUser({
      uid: uid,
      authDetails: authDetails,
      displayName: displayName,
      dbDetails: dbDetails,
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        // Check if errors are encountered.
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
          return '';
        }
        // return back success with refresh of employeedata
        props.onEditConfirmation(true, props.details.displayName);
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Edit
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Display Name:</b> <br></br>
          <input type='text' value={labelDisplayName} id='labelDisplayName' onChange={labelDisplayNameListener} /> <br></br>
          <b>Email:</b> <br></br>
          <input type='text' value={email} id='email' onChange={emailListener} /> <br></br>
          <b>First Name:</b> <br></br>
          <input type='text' value={firstName} id='firstName' onChange={firstNameListener} /> <br></br>
          <b>Last Name:</b> <br></br>
          <input type='text' value={lastName} id='lastName' onChange={lastNameListener} /> <br></br>
          <b>Phone Number:</b> <br></br>
          <input type='text' value={phoneNumber} id='phoneNumber' onChange={phoneNumberListener} /> <br></br>
          <b>Birthday:</b>
          <Form.Control type="date" style={{width:140}}
            onChange={birthdayListener} onKeyDown={(e) => e.preventDefault()}
            inputMode='none' value={birthday}>
          </Form.Control>
          <b>Start Date:</b>
          <Form.Control type="date" style={{width:140}}
            onChange={startDateListener} onKeyDown={(e) => e.preventDefault()}
            inputMode='none' value={startDate}>
          </Form.Control>
          <b>Shift Start:</b><DropdownButton id="shiftStart" title={shiftStart} style={{marginRight:15}}>
            <Dropdown.Item onClick={() => selectShiftTime('8:15 AM')}>8:15 AM</Dropdown.Item>
            <Dropdown.Item onClick={() => selectShiftTime('9:00 AM')}>9:00 AM</Dropdown.Item>
          </DropdownButton>
          <b>Salary/month:</b> <br></br>
          <input type='text' value={salary} id='salary' onChange={salaryListener} />
          <br></br>
          <br></br>
          <b>Vacation Leaves:</b> <br></br>
          { vacationLeaves.length !== 0
            ?
            vacationLeaves.map((leave, arrayIndex) => (
              <Fragment key={`leave-${arrayIndex}`}>
                <div style={{border: "1px solid #1C6EA4", padding: 5, marginTop: 5}}>
                  Year: <br></br>
                  <input type='text' value={leave['year']} onChange={(event) =>
                    {vacationLeavesListener(event.target.value, 'year', arrayIndex)}} /> <br></br>
                  Leave Credits: <br></br>
                  <input type='number' value={leave['credits']} onChange={(event) =>
                    {vacationLeavesListener(Number(event.target.value), 'credits', arrayIndex)}} /> <br></br>
                  <Button style={{marginTop:5}} variant="danger" onClick={() => {removeVacationLeaveHandler(arrayIndex)}}>
                    Delete Vacation Leave Year and Credit
                  </Button>
                </div>
              </Fragment>
            ))
            :
            <b style={{color:"red"}}>No Vacation leaves configured. <br></br></b>
          }
          <Button style={{marginTop:5}} variant="success" onClick={addVacationLeaveHandler}>
            Add Vacation Leave Year and Credit
          </Button>
          <br></br>
          <br></br>
          <b>Sick Leaves:</b> <br></br>
          { sickLeaves.length !== 0
            ?
            sickLeaves.map((leave, arrayIndex) => (
              <Fragment key={`leave-${arrayIndex}`}>
                <div style={{border: "1px solid #1C6EA4", padding: 5, marginTop: 5}}>
                  Year: <br></br>
                  <input type='text' value={leave['year']} onChange={(event) =>
                    {sickLeavesListener(event.target.value, 'year', arrayIndex)}} /> <br></br>
                  Leave Credits: <br></br>
                  <input type='number' value={leave['credits']} onChange={(event) =>
                    {sickLeavesListener(Number(event.target.value), 'credits', arrayIndex)}} /> <br></br>
                  <Button style={{marginTop:5}} variant="danger" onClick={() => {removeSickLeaveHandler(arrayIndex)}}>
                    Delete Sick Leave Year and Credit
                  </Button>
                </div>
              </Fragment>
            ))
            :
            <b style={{color:"red"}}>No Sick leaves configured. <br></br></b>
          }
          <Button style={{marginTop:5}} variant="success" onClick={addSickLeaveHandler}>
            Add Sick Leave Year and Credit
          </Button>
        </Modal.Body>
        <Modal.Footer>
          { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div>
          <Button variant='primary' onClick={() => resetPassword(props.details.email)}>
            Reset Password
          </Button> &nbsp;
          <Button variant="secondary" onClick={cancelHandler}>
            Cancel
          </Button> &nbsp;
          <Button variant="primary" onClick={saveHandler}>
            Save
          </Button>
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditUserModal


