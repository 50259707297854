import MainHeader from '../../components/MainHeader';
import classes from "./AdminViewLeaves.module.css";
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import firebase from "firebase/compat/app";
import Button from 'react-bootstrap/Button';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import { useNavigate } from 'react-router-dom';
import ManageLeaveModal from '../../components/ManageLeaveModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextStatusColor from '../../components/TextStatusColor';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import wcmatch from 'wildcard-match';

const AdminViewLeaves = () => {

  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // leavesList original (initial page render or refresh) array state
  const [origLeavesList, setOrigLeavesList] = useState([]);
  // leavesList array state
  const [leavesList, setLeavesList] = useState([]);
  // DropDown Button Title State
  const [hrReviewDropDownTitle, setHrReviewDropDownTitle] = useState('all');
  // Overall Status DropDown Button Title State
  const [statusDropDownTitle, setStatusDropDownTitle] = useState('all');
  // Display Name input form listener
  const [displayName, setDisplayName] = useState('');

  // let userEmail;
  const navigate = useNavigate()
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
        user.getIdTokenResult().then(function(data) {
          // const isAdmin = data.claims.admin;
          // userEmail = user.email;
          // console.log(isAdmin);
          // console.log(user.email);
          // getUser(user.displayName);
          // console.log(user.displayName);
        }
      );
    } else {
      alert('Unauthorized access, please login.');
      navigate('/');
    }
  });

  useEffect(() => {
      listAllLeaves();
  },[]);

  async function listAllLeaves() {
    // Enable loader when request is being sent.
    setLeavesList([]);
    setLoaderState(true);
    const leavesDocsSnap = await getDocs(collection(db, "leaves"));
    const leavesArray = [];
    leavesDocsSnap.forEach((doc) => {
      for (const key of Object.keys(doc.data())) {
        for (const subKey of Object.keys(doc.data()[key])) {
          leavesArray.push({ displayName: doc.id, dateFiled: subKey, ...doc.data()[key][subKey]});
        }
      }
    });
    leavesArray.sort((a, b) => DateTime.fromFormat(a.dateFiled,'dd-MMM-yyyy') > DateTime.fromFormat(b.dateFiled,'dd-MMM-yyyy') ? 1 : -1);
    setLeavesList(leavesArray);
    setOrigLeavesList(leavesArray);
    setLoaderState(false);
    // console.log(leavesArray);
  }
  ///// end of Event Input Listeners ////

  // Props callback function for parent props
  function getConfirmation(isSuccess) {
    if (isSuccess){
      setHrReviewDropDownTitle('all');
      setStatusDropDownTitle('all');
      setDisplayName('');
      listAllLeaves();
    }
  }

  function refreshHandler() {
    setHrReviewDropDownTitle('all');
    setStatusDropDownTitle('all');
    setDisplayName('');
    listAllLeaves();
  }
  function searchDisplayName(displayName) {
    const isMatch = wcmatch(`*${displayName}*`);
    const leavesArray = [];
    origLeavesList.forEach((user) => {
      if (isMatch(user.displayName)) {
        leavesArray.push(user);
      }
    });
    setLeavesList(leavesArray);
    setHrReviewDropDownTitle('all');
    setStatusDropDownTitle('all');
  }

  function filterHrReview(hrReview) {
    setHrReviewDropDownTitle(hrReview);
    setDisplayName('');
    const leavesArray = [];
    // no filter, search field is reset
    if (hrReview === 'all' && statusDropDownTitle === 'all') {
      setLeavesList(origLeavesList);
    // hrReview all but status is not all
    } else if (hrReview === 'all' && statusDropDownTitle !== 'all') {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.status === statusDropDownTitle) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    // hrReview is not all but status is all
    } else if (hrReview !== 'all' && statusDropDownTitle === 'all') {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.hrReview === hrReview) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    // hrReview is not all but status is not all
    } else {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.hrReview === hrReview && leaveItem.status === statusDropDownTitle) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    }
  }

  function filterStatus(status) {
    setStatusDropDownTitle(status);
    setDisplayName('');
    const leavesArray = [];
    // no filter, search field is reset
    if (status === 'all' && hrReviewDropDownTitle === 'all') {
      setDisplayName('');
      setLeavesList(origLeavesList);
    // hrReview all but status is not all
    } else if (status === 'all' && hrReviewDropDownTitle !== 'all') {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.hrReview === hrReviewDropDownTitle) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    // hrReview is not all but status is all
    } else if (status !== 'all' && hrReviewDropDownTitle === 'all') {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.status === status) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    // hrReview is not all and status is not all
    } else {
      origLeavesList.forEach((leaveItem) => {
        if (leaveItem.status === status && leaveItem.hrReview === hrReviewDropDownTitle) {
          leavesArray.push(leaveItem);
        }
      });
      setLeavesList(leavesArray);
    }
  }

  function displayNameListener(event) {
    setDisplayName(event.target.value);
    searchDisplayName(event.target.value);
  }


  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
    <center>
      <h4 style={{margin:20}}>Manage Leaves</h4>
      <div style={{marginBottom:20}}>
        <label htmlFor='displayName'>Display Username:</label> &nbsp;
        <input type='displayName' value={displayName} id='displayName' onChange={displayNameListener} />
      </div>
      <div style={{display:'inline-flex', alignItems:"baseline"}}>
        <Button
          variant="primary"
          onClick={refreshHandler}
          style={{marginRight:15}}
        >
          Refresh
        </Button>
        <b>HR Review:&nbsp;</b><DropdownButton id="hr-review-dropdown-button" title={hrReviewDropDownTitle} style={{marginRight:15}}>
          <Dropdown.Item onClick={() => filterHrReview('all')}>all</Dropdown.Item>
          <Dropdown.Item onClick={() => filterHrReview('approved')}>approved</Dropdown.Item>
          <Dropdown.Item onClick={() => filterHrReview('pending')}>pending</Dropdown.Item>
          <Dropdown.Item onClick={() => filterHrReview('rejected')}>rejected</Dropdown.Item>
          <Dropdown.Item onClick={() => filterHrReview('expired')}>expired</Dropdown.Item>
        </DropdownButton>
        <b>Status:&nbsp;</b><DropdownButton id="status-dropdown-button" title={statusDropDownTitle}>
          <Dropdown.Item onClick={() => filterStatus('all')}>all</Dropdown.Item>
          <Dropdown.Item onClick={() => filterStatus('approved')}>approved</Dropdown.Item>
          <Dropdown.Item onClick={() => filterStatus('pending')}>pending</Dropdown.Item>
          <Dropdown.Item onClick={() => filterStatus('rejected')}>rejected</Dropdown.Item>
          <Dropdown.Item onClick={() => filterStatus('expired')}>expired</Dropdown.Item>
        </DropdownButton>
      </div>
      <br></br>
      <div className={classes.viewBox}>
      <Container>
        <ul className={classes.tablestyle}>
        <Row>
          <Col xs="auto"><b>Display Name</b></Col>
          <Col xs="auto"><b>Date Filed</b></Col>
          <Col xs="auto"><b>Leave Type</b></Col>
          <Col xs="auto"><b>HR Review</b></Col>
          <Col><b>Status</b></Col>
        </Row>
        {
          leavesList.map((val) => (
            <Row key={val.dateFiled+val.displayName} style={{marginTop:10, alignItems:"baseline"}}>
            <Col xs="auto"> <b>{val.displayName}</b> </Col>
            <Col xs="auto">
            <ManageLeaveModal
              displayName={val.displayName}
              dateFiled={val.dateFiled}
              leaveDates={val.leaveDates}
              reason={val.reason}
              leaveType={val.leaveType}
              approvalImageUrl={val.approvalImageUrl}
              count={val.count}
              hrReview={val.hrReview}
              reviewReason={val.reviewReason}
              status={val.status}
              isAdmin={true}
              isSuperAdmin={false}
              onConfirmation={getConfirmation}
            />
            </Col>
            <Col> <TextStatusColor text={val.leaveType}/> </Col>
            <Col> <TextStatusColor text={val.hrReview}/> </Col>
            <Col> <TextStatusColor text={val.status}/> </Col>
            </Row>
          ))
        }
        </ul>
      </Container>
      </div>
    </center>
    </div>
    </CustomLoader>
  );
};

export default AdminViewLeaves;
