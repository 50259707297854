import * as React from "react";
import MainHeader from "../../components/MainHeader";
import classes from "./UserHelp.module.css";

const UserHelp = () => {

  return (
    <div>
      <MainHeader />
      <center>
      <h4 style={{marginTop:20}}>Regular User Guide</h4>
      <ul className={classes.viewBox}>
        <li> <h6>How to reset your password:</h6>
        Go to Menu {">"} User {">"} User Profile
        Then click the "Reset My Password button"
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Upload Picture" button?</h6>
        The upload picture button will let you upload your selfie.
        It will include your current GPS position so HR and Management
        can check if you are in the correct location while taking the selfie.
        It will also automatically include the current time of day when you took the selfie.
        <br></br><br></br>
        </li>
        <li> <h6>What are the rules for Filing Leaves?</h6>
        1. You cannot file a leave date that is older than today. <br></br>
        2. Saturdays are automatically converted to halfday or 0.5 days. <br></br>
        3. You can file 1 or more leavedates per leave request. <br></br>
        4. You cannot file more than 1 leave request a day. <br></br>
        5. Leave Count means the current number of leaves you are putting in the leave request. <br></br>
        6. Remaining Leave balance is the remaining leaves you can file for the year. <br></br>
        7. Leave reason is required. <br></br>
        8. Leave picture is required (proof of manager/supervisor approval).
        <br></br><br></br>
        </li>
        <li> <h6>What is the "View Leaves" button?</h6>
        This will show you the leaves that you have filed. You cannot edit leaves but you can delete them.
        However, you cannot delete leaves that have been approved or rejected.
        <br></br><br></br>
        </li>
        <li> <h6>What is the leave approval process?</h6>
        The leave is first reviewed by HR and wil be approved/rejected by them. If your leave is approved by HR.
        HR will endorse this to Management for final approval.
        <br></br><br></br>
        </li>
        <li> <h6>What are the leaves calculated?</h6>
        1. Saturdays are automatically set as halfday leaves. <br></br>
        2. Only leaves in pending or approved state are counted against your leave credits. <br></br>
        3. Leaves that are still in pending state but have passed the maximum date for review will be set to expired.
        Expired and rejected leaves do not count against your leave credits.
        <br></br><br></br>
        </li>
      </ul>
      </center>
      <br></br>
      <br></br>
    </div>
  );
}

export default UserHelp;
