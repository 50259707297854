import { db } from '../../../firebaseConfig';
import { Timestamp, collection, doc, setDoc } from 'firebase/firestore/lite';


export const insertDocument = async (collectionName, insertData, documentId) => {

  const collectionRef = collection(db, collectionName);

  insertData.createdAt = Timestamp.now();

  try {
    if (documentId) {
      await setDoc(doc(collectionRef, documentId), insertData);
    } else {
      await setDoc(doc(collectionRef), insertData);
    }
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
