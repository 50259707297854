import * as React from "react";
import MainHeader from "../components/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../components/CustomLoader";
import { db, firebaseObserver } from "../firebaseConfig";
import { collection, getDocs } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import Footer from "../components/Footer";
// import { useAuthContext } from "../components/context/useAuthContext";


const Home = () => {

  // User list state
  const [messageList, setMessageList] = useState([]);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  // useEffect(() => {
  //   firebaseObserver.subscribe('authStateChanged', user => {
  //     if (!user) {
  //       alert('Unauthorized access.');
  //       navigate('/');
  //     }
  //   });
  //   listAllBroadcasts();
  //   return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  // }, [navigate]);

  // const { user } = useAuthContext();
  useEffect(() => {
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    firebaseObserver.subscribe('authOperatorClaim', isOperator => {
      if (isOperator) {
        navigate('/timeInOut');
      }
    });
    listAllBroadcasts();
    return () => {
      firebaseObserver.unsubscribe('authStateChanged');
      firebaseObserver.unsubscribe('authOperatorClaim');
    }
  }, [navigate]);

  async function listAllBroadcasts() {
    // Enable loader when request is being sent.
    setLoaderState(true);

    const broadcastsRef = collection(db, "broadcasts");
    const snapshot = await getDocs(broadcastsRef);
    const messageArray = [];
    snapshot.forEach((doc) => {
      messageArray.push({id: doc.id, ...doc.data()});
    });
    messageArray.sort((a, b) => DateTime.fromFormat(b.id, 'dd-MMM-yyyy') > DateTime.fromFormat(a.id, 'dd-MMM-yyyy') ? 1 : -1);
    setMessageList(messageArray);
    setLoaderState(false);
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>Message updates from HR</h4>
        <ul className={classes.viewBox}>
        {
          messageList.map((val) => (
            <li key={val.id} style={{marginBottom:20}}>
              <b>{val.id}:</b><br></br>
              {val.message}
            </li>
          ))
        }
        </ul>
        </center>
        <br></br>
        <br></br>
        <Footer />
      </div>
    </CustomLoader>
  );
}

export default Home;
