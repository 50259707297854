import { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import classes from './SideBar.module.css';


const SideBar = (props) => {
  // Sidebase open and close state
  const [show, setShow] = useState(false);
  // User SubMenu open and close state
  const [userSubMenuShow, setUserSubMenuShow] = useState(false);
  // Admin SubMenu open and close state
  const [adminSubMenuShow, setAdminSubMenuShow] = useState(false);
  // Super Admin SubMenu open and close state
  const [superAdminSubMenuShow, setSuperAdminSubMenuShow] = useState(false);

  function handleClose () {
    setShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function handleShow () {setShow(true)};

  function toggleUserSubMenu () {
    setUserSubMenuShow(!userSubMenuShow);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleAdminSubMenu () {
    setAdminSubMenuShow(!adminSubMenuShow);
    setUserSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleSuperAdminSubMenu () {
    setSuperAdminSubMenuShow(!superAdminSubMenuShow);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
  }

  return (
    <Fragment>
      <style type="text/css">
        {`
        .btn-flat {
          background-color: purple;
          color: white;
        }

        .btn-xl {
          padding: 1rem 1.5rem;
          font-size: 1.5rem;
        }
        `}
      </style>
      <Button variant="primary" onClick={handleShow} size="lg" >
        MENU &nbsp; <i className="fa-solid fa-bars fa-xl"></i>
      </Button>
      <div style={{margin:10}}>
        <Badge
          pill
          bg="warning"
          text="dark"
        >
          {props.displayName}
        </Badge>
      </div>
      <Offcanvas show={show} onHide={handleClose} style={
        {
          backgroundColor:"#044599",
          color: "whitesmoke",
          width: 200
        }
      }>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={classes.linkList}>
            <li>
              { props.isOperator ?
                <Link to='/timeInOut'>
                  <Button>
                    Home
                  </Button>
                </Link>
              :
                <Link to='/home'>
                  <Button>
                    Home
                  </Button>
                </Link>
              }
            </li>
            <li>
              { props.isOperator ?
                null
              :
                <Button onClick={toggleUserSubMenu}>
                  User
                </Button>
              }
            </li>
            <Collapse in={userSubMenuShow}>
            <ul>
              <li>
                <Link to='/userprofile'>
                <Button>
                  User Profile
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/uploadscreenshot'>
                <Button>
                  Upload Picture
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/viewscreenshots'>
                <Button>
                  View Uploads
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/leaves'>
                <Button>
                  File Leaves
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/viewleaves'>
                <Button>
                  View Leaves
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/viewtimeinout'>
                <Button>
                  Time In/Out Report
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/userhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>
            <li>
              { props.isAdmin ? <Button onClick={toggleAdminSubMenu}>
                Admin
              </Button>
              : null }
            </li>
            <Collapse in={adminSubMenuShow}>
            <ul>
              <li>
                <Link to='/audituploads'>
                <Button>
                  Audit Uploads
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/createuser'>
                <Button>
                  Create User
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/manageusers'>
                <Button>
                  Manage Users
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/managebroadcasts'>
                <Button>
                  Broadcasts
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/adminviewleaves'>
                <Button>
                  Manage Leaves
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/audittimeinout'>
                <Button>
                  Audit Time In/Out
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/adminhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>
            <li>
              { props.isSuperAdmin ?
              <Button onClick={toggleSuperAdminSubMenu}>
                Super Admin
              </Button>
              : null }
            </li>
            <Collapse in={superAdminSubMenuShow}>
            <ul>
              <li>
                <Link to='/manageadmins'>
                <Button>
                  Manage Admins
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/superadminviewleaves'>
                <Button>
                  Manage Leaves
                </Button>
                </Link>
              </li>
              <li>
                <Link to='/superadminhelp'>
                <Button>
                  Help
                </Button>
                </Link>
              </li>
            </ul>
            </Collapse>
            <li>
              <Link to='/signout'>
              <Button>
                Sign Out
              </Button>
            </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default SideBar;
