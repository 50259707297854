import MainHeader from '../../components/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from './CreateUser.module.css';
import { Fragment, useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import Button from 'react-bootstrap/Button';
import { firebaseObserver, functions } from '../../firebaseConfig';
import { DateTime } from 'luxon';
import { httpsCallable } from 'firebase/functions';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const CreateUser = () => {

  // Display Name input form listener
  const [displayName, setDisplayName] = useState('');
  // Regex for display name validation
  const displayNameRegex = new RegExp('[\\s]');

  // Email input form listener
  const [email, setEmail] = useState('');
  // Password input form listener
  const [password, setPassword] = useState('');
  // Regex for password (minimum 8 chars)
  const passwordRegex = new RegExp('(?=.{8,})');
  // Phone Number input form listener
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  // lastName
  const [lastName, setLastName] = useState('');
  // birthday
  const [birthday, setBirthday] = useState('');
  // startDate
  const [startDate, setStartDate] = useState('');
  // shiftStart
  const [shiftStart, setShiftStart] = useState('8:15 AM');
  // salary
  const [salary, setSalary] = useState('');
  // vacationleaveItems
  const [vacationLeaves, setVacationLeaves] = useState([{year: DateTime.local().year.toString(), credits: 10}]);
  // sickleaveItems
  const [sickLeaves, setSickLeaves] = useState([{year: DateTime.local().year.toString(), credits: 10}]);

  // Create user Result
  const [createResult, setCreateResult] = useState('');
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authAdminClaim', adminClaim => {
      if (!adminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    return () => { firebaseObserver.unsubscribe('authAdminClaim'); }
  }, [navigate]);

  // Input Listeners
  function displayNameListener(event) {
    setDisplayName(event.target.value);
  }
  function emailListener(event) {
    setEmail(event.target.value);
  }
  function passwordListener(event) {
    setPassword(event.target.value);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }
  function birthdayListener(event) {
    setBirthday(event.target.value);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
  }
  function salaryListener(event) {
    setSalary(event.target.value);
  }
  function vacationLeavesListener(value, objectKey, arrayIndex) {
    const tempLeavesArray = [].concat(vacationLeaves);
    tempLeavesArray[arrayIndex][objectKey] = value;
    setVacationLeaves(tempLeavesArray);
  }
  function sickLeavesListener(value, objectKey, arrayIndex) {
    const tempLeavesArray = [].concat(sickLeaves);
    tempLeavesArray[arrayIndex][objectKey] = value;
    setSickLeaves(tempLeavesArray);
  }
  function selectShiftTime(shiftStartInput) {
    setShiftStart(shiftStartInput)
  }
  function resetInputFields() {
    setDisplayName('');
    setEmail('');
    setPassword('');
    setPhoneNumber('');
    setBirthday('');
    setFirstName('');
    setLastName('');
    setStartDate('');
    setSalary('');
    setShiftStart('8:15 AM');
  }

  // triggering search when button is pressed
  async function createUser() {
    setCreateResult('');
    const data = {
      displayName: displayName,
      labelDisplayName: displayName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
      firstName: firstName,
      lastName: lastName,
      birthday: birthday,
      startDate: startDate,
      shiftStart: shiftStart,
      salary: salary,
      vacationLeaves: vacationLeaves,
      sickLeaves: sickLeaves,
    };
    if (!data.phoneNumber) {
      delete data.phoneNumber;
    }
    if (displayNameRegex.test(displayName) || !displayName) {
      alert(`Display Username is required and must not contain a SPACE.`);
      return;
    }
    // if not less than 8 characters
    if (!passwordRegex.test(password)) {
      alert(`Password must be 8-16 characters long.`);
      return;
    }
    // Enable loader when request is being sent.
    setLoaderState(true);

    const createUser = httpsCallable(functions, 'createUser');
    // console.log(data);
    await createUser(
      data
    ).then((response) => {
        console.log(response);
        // Disable loader when results are in
        setLoaderState(false);
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
        } else {
          // Action to trigger after success.
          setCreateResult(response.data);
          resetInputFields();
        }
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center>
        <h3 style={{marginTop:15}}>Create User</h3>
        <div className={classes.mainInputContainer}>
          <li>
            <label htmlFor='displayName'>Display Username:</label> &nbsp;
            <input type='displayName' value={displayName} id='displayName' onChange={displayNameListener} />
          </li>
          <li>
            <label htmlFor='email'>Email: </label> &nbsp;
            <input type='email' value={email} id='email' onChange={emailListener} />
          </li>
          <li>
            <label htmlFor='text'>Password: </label> &nbsp;
            <input type='text' value={password} id='password' onChange={passwordListener} />
          </li>
          <li>
            <label htmlFor='phonenumber'>Phone Number(optional): </label> &nbsp;
            <input type='phonenumber' value={phoneNumber} id='phonenumber' onChange={phoneNumberListener} />
          </li>
          <li>
          First Name: &nbsp;
          <input type='text' value={firstName} id='firstName' onChange={firstNameListener} />
          </li>
          <li>
          Last Name: &nbsp;
          <input type='text' value={lastName} id='lastName' onChange={lastNameListener} />
          </li>
          <li>
          Birthday: &nbsp;
          <input type="date" style={{width:190}}
            onChange={birthdayListener} onKeyDown={(e) => e.preventDefault()}
            inputMode='none' value={birthday}/>
          </li>
          <li>
          Start Date: &nbsp;
          <input type="date" style={{width:190}}
            onChange={startDateListener} onKeyDown={(e) => e.preventDefault()}
            inputMode='none' value={startDate}/>
          </li>
          <li>
            <b>Shift Start</b>
            <DropdownButton id="shiftStart" title={shiftStart}>
              <Dropdown.Item onClick={() => selectShiftTime('8:15 AM')}>8:15 AM</Dropdown.Item>
              <Dropdown.Item onClick={() => selectShiftTime('9:00 AM')}>9:00 AM</Dropdown.Item>
            </DropdownButton>
          </li>
          <li>
          Salary/month: &nbsp;
          <input type='text' value={salary} id='salary' onChange={salaryListener} />
          </li>
          <b>Vacation Leaves:</b>
          { vacationLeaves.length !== 0
            ?
            vacationLeaves.map((leave, arrayIndex) => (
            <Fragment key={`leave-${arrayIndex}`}>
              <div style={{border: "1px solid #1C6EA4", padding: 5, marginTop: 5}}>
              <li>
                Year: &nbsp;
                <input type='text' value={leave['year']} onChange={(event) =>
                  {vacationLeavesListener(event.target.value, 'year', arrayIndex)}} />
              </li>
              <li>
                Leave Credits:
                <input type='number' value={leave['credits']} onChange={(event) =>
                  {vacationLeavesListener(Number(event.target.value), 'credits', arrayIndex)}} />
              </li>
              </div>
            </Fragment>
            ))
            :
            <b style={{color:"red"}}>No leaves configured. <br></br></b>
          }
          <b>Sick Leaves:</b>
          { sickLeaves.length !== 0
            ?
            sickLeaves.map((leave, arrayIndex) => (
            <Fragment key={`leave-${arrayIndex}`}>
              <div style={{border: "1px solid #1C6EA4", padding: 5, marginTop: 5}}>
              <li>
                Year: &nbsp;
                <input type='text' value={leave['year']} onChange={(event) =>
                  {sickLeavesListener(event.target.value, 'year', arrayIndex)}} />
              </li>
              <li>
                Leave Credits:
                <input type='number' value={leave['credits']} onChange={(event) =>
                  {sickLeavesListener(Number(event.target.value), 'credits', arrayIndex)}} />
              </li>
              </div>
            </Fragment>
            ))
            :
            <b style={{color:"red"}}>No leaves configured. <br></br></b>
          }
          <small style={{color:"red"}}>
          *Display Username and Email cannot be <br></br>
          changed once the user has been created. <br></br>
          *Follow this rule: firstname+lastname for Display Username <br></br>
          *example: firstname: Shane lastname: Toscano = ShaneToscano <br></br>
          </small>
          <br></br>
          <Button variant="primary" onClick={createUser}>Create User</Button>
        </div>
        <br></br>
        { createResult ?
        <div className={classes.resultContainer}>
        <h3> Successfully created the following user:</h3>
        <li><label>Display Name: {createResult.displayName} </label></li>
        <li><label>Email: {createResult.email} </label></li>
        <li><label>First Name: {createResult.firstName} </label></li>
        <li><label>Last Name: {createResult.lastName} </label></li>
        <li><label>Birthday: {createResult.birthday} </label></li>
        <li><label>Start Date: {createResult.startDate} </label></li>
        <li><label>Shift Start: {createResult.shiftStart} </label></li>
        <li><label>Salary/Month: {createResult.salary} </label></li>
        <li><label>{createResult.vacationLeaves[0].credits} vacation leave credits for the year {createResult.vacationLeaves[0].year}</label></li>
        <li><label>{createResult.sickLeaves[0].credits} sick leave credits for the year {createResult.sickLeaves[0].year}</label></li>
        { createResult.phoneNumber ? <li><label>Phone Number: {createResult.phoneNumber} </label></li> : null }
        </div> : null }
      </center>
    </div>
    </CustomLoader>
  );
};

export default CreateUser;
