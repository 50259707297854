import classes from './MainHeader.module.css';
import { useState } from 'react';
import firebase from 'firebase/compat/app';
import SideBar from './SideBar';

// Navigation user experience
//
// Home -> message board
// User
//  |--> Upload Picture (done)
//  |--> View uploads (done)
//  |--> View Leave credits (x)
//  |--> File Leave of absence (x)
//  |--> View time in/out (x)
// Admin
//  |--> Audit Uploads
//  |--> Add Message Board
//  |--> Add/Remove Users
//  |--> View User List
//  |--> View Admin List
//  |--> View Leave credits (x)
//  |--> View time in/out (x)
// SuperAdmin
//  |--> Add Admin User
//  |--> Add Super Admin User
//  |--> Add/Remove Users
//  |--> View User List
//  |--> View Admin List
//  |--> View Super Admin List
//  |--> Approve Leave of absence (x)
// Sign Out

const MainHeader = () => {

  const [displayName, setDisplayName] = useState('');
  const [labelDisplayName, setLabelDisplayName] = useState('');
  const [displaySuperAdmin, setDisplaySuperAdmin] = useState(false);
  const [displayAdmin, setDisplayAdmin] = useState(false);
  const [displayOperator, setDisplayOperator] = useState(false);

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      setDisplayName(user.displayName);
      user.getIdTokenResult().then(function(data) {
        setDisplayAdmin(data.claims?.admin);
        setDisplaySuperAdmin(data.claims?.superadmin);
        setDisplayOperator(data.claims?.operator);
        setLabelDisplayName(data.claims?.labelDisplayName);
      });
    }
  });

  return (
    <header className={classes.header} >
        <SideBar
          displayName={labelDisplayName || displayName}
          isAdmin={displayAdmin}
          isSuperAdmin={displaySuperAdmin}
          isOperator={displayOperator}
        />
    </header>
  );
};

export default MainHeader;

