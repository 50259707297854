import MainHeader from '../../components/MainHeader';
import classes from "./ViewLeaves.module.css";
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import Button from 'react-bootstrap/Button';
import { db, firebaseObserver } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import { useNavigate } from 'react-router-dom';
import ManageLeaveModal from '../../components/ManageLeaveModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextStatusColor from '../../components/TextStatusColor';
import { useAuthContext } from '../../components/context/useAuthContext';

const ViewLeaves = () => {

  // User Auth Context
  const { user } = useAuthContext();
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Display Name input form listener
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Key states
  // leavesList array state
  const [leavesList, setLeavesList] = useState([]);
  // remaining vacation leave balance state
  const [leaveBalanceVacation, setLeaveBalanceVacation] = useState(0);
  // remaining sick leave balance state
  const [leaveBalanceSick, setLeaveBalanceSick] = useState(0);


  // Old code, remove on next release
  // // let userEmail;
  // const navigate = useNavigate()
  // firebase.auth().onAuthStateChanged(function(user) {
  //   if (user) {
  //       user.getIdTokenResult().then(function(data) {
  //         // const isAdmin = data.claims.admin;
  //         // userEmail = user.email;
  //         // console.log(isAdmin);
  //         // console.log(user.email);
  //         // getUser(user.displayName);
  //         // console.log(user.displayName);
  //         setDisplayName(user.displayName);
  //       }
  //     );
  //   } else {
  //     alert('Unauthorized access, please login.');
  //     navigate('/');
  //   }
  // });

  // useEffect(() => {
  //   if (displayName) {
  //     listAllLeaves(displayName);
  //   }
  // },[displayName]);

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    if (displayName) {
      setLoaderState(true);
      listAllLeaves(displayName);
      setLoaderState(false);
    }
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function listAllLeaves(displayName) {
    // Enable loader when request is being sent.
    setLeavesList([]);
    setLoaderState(true);
    const leavesRef = doc(db, "leaves", displayName);
    const leavesDocSnap = await getDoc(leavesRef);
    const leavesArray = [];
    if (leavesDocSnap.exists()) {
      for (const key of Object.keys(leavesDocSnap.data())) {
        for (const subKey of Object.keys(leavesDocSnap.data()[key])) {
          leavesArray.push({ dateFiled: subKey, ...leavesDocSnap.data()[key][subKey]});
        }
      }
    }
    leavesArray.sort((a, b) => DateTime.fromFormat(a.dateFiled,'dd-MMM-yyyy') > DateTime.fromFormat(b.dateFiled,'dd-MMM-yyyy') ? 1 : -1);
    setLeavesList(leavesArray);
    setLeaveBalanceVacation(await getLeaveBalance(displayName, 'Vacation Leave', 'vacationLeaves'));
    setLeaveBalanceSick(await getLeaveBalance(displayName, 'Sick Leave', 'sickLeaves'));
    setLoaderState(false);
    // console.log(leavesArray);
  }
  ///// end of Event Input Listeners ////

  async function getLeaveBalance(displayName, leaveType, leaveTypeKey) {
    // Get year of leave date to determine balance remaining.
    let leaveBalance = 0;
    const year = DateTime.local().year.toString();
    const employeesRef = doc(db, "employees", displayName);
    const empDocSnap = await getDoc(employeesRef);
    if (empDocSnap.exists()) {
      // Check if user has leaves configured
      if (empDocSnap.data()[leaveTypeKey]) {
        let leaveCredits = 0;
        empDocSnap.data()[leaveTypeKey].forEach((leaveItem) => {
          if (leaveItem.year === year) {
            leaveCredits = leaveItem.credits;
          }
        });
        const leavesRef = doc(db, "leaves", displayName);
        const leaveDocSnap = await getDoc(leavesRef);
        // Iterate through existing leaves in leaves collection
        if (leaveDocSnap.exists()) {
          const leave = leaveDocSnap.data();
          // Iterate through each datfiled based on year
          for (const key of Object.keys(leave)) {
            // Iterate through leavecount of each dateFiled
            for (const subKey of Object.keys(leave[key])) {
              // Check if leave status is approved or pending
              //   include in total if they are
              if ((leave[key][subKey].status === 'pending' ||
                  leave[key][subKey].status === 'approved' ) &&
                  leave[key][subKey].leaveType === leaveType) {
                    leaveBalance += leave[key][subKey].count;
              }
            }
          }
        }
        const remainingLeaves = leaveCredits - leaveBalance;
        return remainingLeaves;
      } else {
        alert(`No leave credits configured for the year ${year}, please ask HR for assistance.`);
        return 0;
      }
    }
    alert(`No leave credits configured for the year ${year}, please ask HR for assistance.`);
    return null
  }

  // Props callback function for parent props
  function getConfirmation(isSuccess) {
    if (isSuccess){
      listAllLeaves(displayName);
    }
  }
  // Sort files Ascending order
  function sortItemsAsc() {
    const myData = [].concat(leavesList)
    myData.sort((a, b) => DateTime.fromFormat(a.dateFiled,'dd-MMM-yyyy') > DateTime.fromFormat(b.dateFiled,'dd-MMM-yyyy') ? 1 : -1);
    setLeavesList(myData);
  }

  // Sort files Descending order
  function sortItemsDesc() {
    const myData = [].concat(leavesList)
    myData.sort((a, b) => DateTime.fromFormat(b.dateFiled,'dd-MMM-yyyy') > DateTime.fromFormat(a.dateFiled,'dd-MMM-yyyy') ? 1 : -1);
    setLeavesList(myData);
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
    <center>
      <h4 style={{marginTop:20}}>View Leaves</h4>
      <div style={{display:'inline-block'}}>
        <label htmlFor='displayName'><b>Display Username:</b>  {displayName}</label>
        <br></br>
        <label htmlFor='vacationleaveBalance'><b>Remaining Vacation Leave Balance:</b> {leaveBalanceVacation}</label>
        <br></br>
        <label htmlFor='vacationleaveBalance'><b>Remaining Sick Leave Balance:</b> {leaveBalanceSick}</label>
        <br></br>
        <Button
          variant="primary"
          onClick={() => getConfirmation(true)}
          style={{marginTop:10}}
          className={classes.buttonStyle}
        >
          Refresh
        </Button> &nbsp;
        <Button
          variant="primary"
          onClick={sortItemsAsc}
          style={{marginTop:10}}
          className={classes.buttonStyle}
        >
          Sort Up
        </Button> &nbsp;
        <Button
          variant="primary"
          onClick={sortItemsDesc}
          style={{marginTop:10}}
          className={classes.buttonStyle}
        >
          Sort Down
        </Button>
      </div>
      <br></br>
      <div className={classes.viewBox}>
      <Container>
        <ul className={classes.tablestyle}>
        <Row>
          <Col xs="auto">&nbsp; &nbsp;<b>Date Filed</b> &nbsp;</Col>
          <Col xs="auto">&nbsp; &nbsp;<b>Leave Type</b></Col>
          <Col xs="auto"><b>HR Review</b></Col>
          <Col><b>Status</b></Col>
        </Row>
        {
          leavesList.map((val) => (
            <Row key={val.dateFiled} style={{marginTop:10}}>
            <Col xs="auto">
            <ManageLeaveModal
              displayName={displayName}
              dateFiled={val.dateFiled}
              leaveDates={val.leaveDates}
              reason={val.reason}
              leaveType={val.leaveType}
              approvalImageUrl={val.approvalImageUrl}
              count={val.count}
              hrReview={val.hrReview}
              reviewReason={val.reviewReason}
              status={val.status}
              isAdmin={false}
              isSuperAdmin={false}
              onConfirmation={getConfirmation}
            />
            </Col>
            <Col> <TextStatusColor text={val.leaveType}/> </Col>
            <Col> <TextStatusColor text={val.hrReview}/> </Col>
            <Col> <TextStatusColor text={val.status}/> </Col>
            </Row>
          ))
        }
        </ul>
      </Container>
      </div>
    </center>
    </div>
    </CustomLoader>
  );
};

export default ViewLeaves;
