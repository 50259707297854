import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";


const DeleteBroadcastModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function yesHandler() {
    setLoaderState(true);
    await deleteBroadcast(props.id);
    props.onDeleteConfirmation(true, props.id);
    setShow(false);
  }

  function noHandler() {
    props.onDeleteConfirmation(false, props.id);
    setShow(false);
  }

  async function deleteBroadcast(id) {
    await deleteDoc(doc(db, "broadcasts", id));
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Delete
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you really, really sure you want to delete this message:</h5>
          Date: {props.id} <br></br>
          Message: {props.message}
        </Modal.Body>
        <Modal.Footer>
          { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div>
          <Button variant="secondary" onClick={noHandler}>
            No
          </Button>
            &nbsp;
          <Button variant="primary" onClick={yesHandler}>
            Yes
          </Button>
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DeleteBroadcastModal

