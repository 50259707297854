import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./components/context/AuthProvider.js";
import App from "./App.js";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ManageUsers from "./pages/Admin/ManageUsers";
import AuditUploads from "./pages/Admin/AuditUploads.js";
import AuditDetails from "./pages/Admin/AuditDetails.js";
import CreateUser from "./pages/Admin/CreateUser.js";
import SignOut from "./pages/SignOut.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserProfile from "./pages/User/UserProfile.js";
import ManageBroadcasts from "./pages/Admin/ManageBroadcasts.js";
import ManageAdmins from "./pages/SuperAdmin/ManageAdmins.js";
import FileLeaves from "./pages/User/FileLeaves.js";
import ViewLeaves from "./pages/User/ViewLeaves.js";
import AdminViewLeaves from "./pages/Admin/AdminViewLeaves.js";
import SuperAdminViewLeaves from "./pages/SuperAdmin/SuperAdminViewLeaves.js";
import UserHelp from "./pages/User/UserHelp.js";
import AdminHelp from "./pages/Admin/AdminHelp.js";
import SuperAdminHelp from "./pages/SuperAdmin/SuperAdminHelp.js";
import ViewTimeInOutReport from "./pages/User/ViewTimeInOutReport.js";
import AuditTimeInOutReport from "./pages/Admin/AuditTimeInOut.js";
import AuditTimeInOutDetails from "./pages/Admin/AuditTimeInOutDetails.js";
import UploadScreenshotV2 from "./pages/User/UploadScreenshotV2.js";
import ViewScreenshotsV2 from "./pages/User/ViewScreenshotsV2.js";
import TimeInOutV2 from "./pages/Operator/TimeInOutV2.js";

// import FirstAdmin from "./pages/SuperAdmin/FirstSetupAdmin.js";

ReactDOM.render(
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/uploadscreenshot" element={<UploadScreenshotV2 />} />
        <Route path="/viewscreenshots" element={<ViewScreenshotsV2 />} />
        <Route path="/leaves" element={<FileLeaves />} />
        <Route path="/viewleaves" element={<ViewLeaves />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/viewtimeinout" element={<ViewTimeInOutReport />} />
        <Route path="/userhelp" element={<UserHelp />} />
        <Route path="/manageadmins" element={<ManageAdmins />} />
        <Route path="/manageusers" element={<ManageUsers />} />
        <Route path="/audituploads" element={<AuditUploads />} />
        <Route path="/audituploads/details" element={<AuditDetails />} />
        <Route path="/audittimeinout" element={<AuditTimeInOutReport />} />
        <Route path="/audittimeinout/details" element={<AuditTimeInOutDetails />} />
        <Route path="/adminviewleaves" element={<AdminViewLeaves />} />
        <Route path="/adminhelp" element={<AdminHelp />} />
        <Route path="/superadminviewleaves" element={<SuperAdminViewLeaves />} />
        <Route path="/superadminhelp" element={<SuperAdminHelp />} />
        <Route path="/createuser" element={<CreateUser />} />
        <Route path="/managebroadcasts" element={<ManageBroadcasts />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/timeInOut" element={<TimeInOutV2 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
    </BrowserRouter>,
  document.getElementById("root")
);

// Only use once during initial deployment for first admin user.
// <Route path="/secret" element={<FirstAdmin />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
