import classes from "./Footer.module.css";

function Footer () {
  return (
    <div className={classes.mainFooter}>
    <center>
      <div style={{color:'white'}}>
        Copyright © 2022 TechJumpStartUp Pty ltd. All rights reserved.
      </div>
    </center>
    </div>
  )
}

export default Footer;
