import Button from "react-bootstrap/Button";
import classes from "./SelfieCameraModal.module.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import React, { useState } from 'react';
import { GeoPoint } from 'firebase/firestore/lite';
import { DateTime } from "luxon";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import { useGeolocated } from 'react-geolocated';
import { storage } from '../firebaseConfig';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { v4 as uuidv4 } from 'uuid';
import { insertDocument } from "./database/mainColl/insert";


const SelfieCameraModalV2 = (props) => {

  // Modal State
  const [show, setShow] = useState(props.show);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // DateTimeStamp
  const [dateTimeStamp, setDateTimeStamp] = useState();
  // State to store uploaded file uri for preview
  const [fileSuccessImage, setFileSuccessImage] = useState('');

  // HTML 5 Camera States
  const [cameraState, setCameraState] = useState(true);
  const [cameraInErrorStatus, setCameraInErrorStatus] = useState(false);
  // end of HTML 5 Camera States

  // Modal Title
  const modalTitle = 'Time Entry';
  // Coordinates
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

  const selectedUser = props.selectedUser;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function okHandler() {
    props.onTimeInOutConfirmation(true);
    setCameraState(false);
    setLoaderState(false);
    setShow(false);
  }

  ///// Event Handlers ////
  function validateStateVariables() {
    if (!isGeolocationAvailable) {
      alert("Your browser does not support Geolocation, please use Google Chrome.");
      window.location.reload(false);
      return false;
    }
    if (!isGeolocationEnabled) {
      alert("Reenable Geo location in your Phone.");
      window.location.reload(false);
      return false;
    }
    return true;
  }

  async function uploadToFirebaseStorage(storageRef, file) {
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }

  async function fileChangeHandler(dataUri, isBase64) {
    if (!validateStateVariables()) {
      return;
    }
    let imageFile = dataUri;
    let fileName;
    let processedFile;
    // If taken from mediaDevice camera, convert base64 to binary
    if (isBase64) {
        const fileExtension = getFileExtension(imageFile.split(',')[0]);
        fileName = uuidv4().concat('.').concat(fileExtension);
        processedFile = dataURLtoFile(imageFile, fileName);
    } else {
        processedFile = dataUri.target.files[0];
    }

    // Requery GPS coordinates
    getPosition();
    // Set date variables
    const dateTimeStamp = DateTime.now().setZone('Asia/Manila').toFormat('dd-MMM-yyyy h:mm a');
    setDateTimeStamp(dateTimeStamp);
    const dateToday = DateTime.local().toFormat('dd-MMM-yyyy');

    // If image is present, process data payload.
    if (processedFile) {
      let fileName;
      const timeEntry = {};
      timeEntry.displayName = selectedUser.displayName;
      timeEntry.timestamp = dateTimeStamp;
      const randomFileId = uuidv4();
      const fileExtension = processedFile.name.split('.')[1];
      //  console.log(fileExtension);
      fileName = randomFileId.concat('.').concat(fileExtension);
      timeEntry.imagePath = `/timeEntries/${selectedUser.displayName}/${dateToday}/${fileName}`;
      timeEntry.timeInCoords = new GeoPoint(Number(coords.latitude), Number(coords.longitude));

      // Loading overlay
      setLoaderState(true);

      // Save image to firebase storage
      const storageRef = ref(storage, `/timeEntries/${selectedUser.displayName}/${dateToday}/${fileName}`);
      const imageUrl = await uploadToFirebaseStorage(storageRef, processedFile);
      timeEntry.imageUrl = imageUrl;
      if (timeEntry.shiftStart) {
        timeEntry.shiftStart = selectedUser.shiftStart;
      } else {
        timeEntry.shiftStart = "8:15 AM";
      }
      await insertDocument("timeEntries", timeEntry);

      setFileSuccessImage(imageUrl);
      setCameraState(false);
      setLoaderState(false);
      setFileSuccessImage(imageUrl);
    }
  }

  ///// end of Event Handlers ////

  ///// Camera html5 functions ////
  async function onTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    // console.log(dataUri);
    await fileChangeHandler(dataUri, true);
    // console.log(videoFirstRender.current);
  }
  function onCameraError (error) {
    alert('Your device does not support modern HTML5 camera features. Switching to native HTML mode.')
    setCameraInErrorStatus(true);
    console.error('onCameraError', error);
  }
  function onCameraStart (stream) {
    // videoRef.current = stream;
    console.log('onCameraStart');
  }
  async function onCameraStop () {
    console.log('onCameraStop');
    // if (videoFirstRender.current === 1) {
    //     setCameraState(false);
    //     window.location.reload(false);
    // }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
  function getFileExtension(mime) {
    const mimeType = mime.match(/:(.*?);/)[1];
    return mimeType.split('/')[1];
  }
  ///// End of Camera html5 functions ////

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Time Entry
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Button variant="danger" onClick={okHandler}>
            EXIT
          </Button>
        </Modal.Header>
        <Modal.Body>
        { fileSuccessImage
          ?  <React.Fragment>
              <h5 style={{marginTop:10}}> SUCCESS </h5>
              <b>{selectedUser.labelDisplayName || selectedUser.displayName}</b> has successfully complete <b>Time Entry</b> at {dateTimeStamp}.
              <br></br>
              <br></br>
              <img src={fileSuccessImage} style={{width:320, height:240}} alt='' />
            </React.Fragment>
          :
            <React.Fragment>
            { loaderState
              ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              : <React.Fragment>
                { cameraState
                  ? <React.Fragment>
                  { cameraInErrorStatus
                    ? <React.Fragment>
                      <label htmlFor="file-upload" className={classes.customFileUpload}>
                        Take a Picture
                      </label>
                      <input id="file-upload"
                        type="file"
                        name="Take A picture"
                        style={{display:'none'}}
                        onChange={(event) => {fileChangeHandler(event, false)}}
                        accept="/image/*"
                        capture="user"
                      />
                      <br></br>
                    </React.Fragment>
                    : <Camera
                      onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
                      onCameraError = { (error) => { onCameraError(error); } }
                      idealFacingMode = {FACING_MODES.USER}
                      idealResolution = {{width: 1280, height: 720}}
                      imageType = {IMAGE_TYPES.JPG}
                      imageCompression = {0.5}
                      isMaxResolution = {false}
                      isImageMirror = {false}
                      isSilentMode = {true}
                      isDisplayStartCameraError = {true}
                      isFullscreen = {false}
                      sizeFactor = {0.5}
                      onCameraStart = { (stream) => { onCameraStart(stream); } }
                      onCameraStop = { () => { onCameraStop(); } }
                    />
                  }
                  </React.Fragment>
                  : <Button
                    variant="primary"
                    onClick={() => {setCameraState(true)}}
                    style={{marginTop:10}}
                  >
                    Take a Picture
                  </Button>
                }
                </React.Fragment>
            }
            </React.Fragment>
          }
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          { loaderState
            ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              />
            : <React.Fragment>
              { cameraState
              ? null
              : <div>
                <Button variant="primary" onClick={okHandler}>
                  EXIT
                </Button>
              </div>
            } </React.Fragment>
          }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

}

export default SelfieCameraModalV2;

