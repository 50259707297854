import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const DeleteUserModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function yesHandler() {
    setLoaderState(true);
    await deleteUser(props.uid, props.displayName);
    setShow(false);
  }

  function noHandler() {
    props.onDeleteConfirmation(false, props.displayName);
    setShow(false);
  }

  async function deleteUser(uid, displayName) {
    const deleteUser = httpsCallable(functions, 'deleteUser');
    await deleteUser({
      uid: uid,
      displayName: displayName
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        // Check if errors are encountered.
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
          return '';
        }
        // return back success with removal of displayName from the list
        props.onDeleteConfirmation(true, props.displayName);
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        Delete
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you really, really sure you want to delete:</h5>
          <b>Display Name:</b> {props.details.labelDisplayName} <br></br>
          <b>Email:</b> {props.email}
        </Modal.Body>
        <Modal.Footer>
          { loaderState
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            : <div>
                <Button variant="secondary" onClick={noHandler}>
                  No
                </Button>
                  &nbsp;
                <Button variant="primary" onClick={yesHandler}>
                  Yes
                </Button>
              </div>
          }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DeleteUserModal

