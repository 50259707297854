import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ImagePopUpModal = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button style={{borderRadius:20}}variant="primary" onClick={handleShow}>
        { props.imgUrlIcon
          ?
            <img src={props.imgUrlIcon} width="50" height="50" alt=""></img>
          :
           <b>{props.text}</b>
        }
        &nbsp;
        {props.timestamp}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.timestamp}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img alt="" src={props.imgUrl} className="img-fluid" />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ImagePopUpModal

