import React, { useState, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import ImagePopUpModal from "./ImagePopUpModal";
import TextStatusColor from "./TextStatusColor";
import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";


const ManageLeaveModal = (props) => {

  // Modal State
  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // HrReview state
  const [reviewReason, setReviewReason] = useState(props.reviewReason);
  // PDF image state
  const [pdfImage, setPdfImage] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  async function clickHandler(displayName, dateFiled, remove, hrstatus, status, reviewReason)  {
    // Review Reason must not be empty when rejecting leaves
    if ((hrstatus === 'rejected' || status === 'rejected') && !reviewReason) {
      alert('You must put a reason when rejecting leaves.');
      return '';
    }
    // Enable loader when request is being sent.
    setLoaderState(true);
    const editEmployeeLeave = httpsCallable(functions, 'editEmployeeLeave');
    await editEmployeeLeave({
      displayName: displayName,
      dateFiled: dateFiled,
      delete: remove,
      hrReview: hrstatus,
      status: status,
      reviewReason: reviewReason,
    })
    .then((response) => {
      setLoaderState(false);
      if (response?.data?.httpErrorCode) {
        alert(response.data.details);
      } else {
        props.onConfirmation(true);
        alert(response.data.status);
        setShow(false);
      }
    })
    .catch((error) => {
      setLoaderState(false);
      alert(error);
    });
  };

  function cancelHandler() {
    setReviewReason(props.reviewReason);
    setShow(false)
  }
  function reviewReasonListener(event) {
    setReviewReason(event.target.value);
  }

  async function genereatePdfHandler() {
    setLoaderState(true);
    const generateLeavePdf = httpsCallable(functions, 'generateLeavePdf');
    await generateLeavePdf({
      displayName: props.displayName,
      dateFiled: props.dateFiled,
      leaveDates: props.leaveDates.reduce( (s,x,i) => s+(i>0 ? ', ' : '') + (x==null ? '' : x), ''),
      leaveType: props.leaveType,
      reason: props.reason
    })
      .then((response) => {
        setPdfImage(response.data.url[0]);
        setLoaderState(false);
        if (response?.data?.httpErrorCode) {
          alert(response.data.details);
        }
      })
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        {props.dateFiled}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center><h5>Overall Leave Status:</h5>
          <TextStatusColor text={props.status}/>
          <br></br>
          {props.reviewReason}
          </center>
          <b>Date Filed:</b> {props.dateFiled} <br></br>
          <b>Leave Dates:</b> {props.leaveDates.reduce( (s,x,i) => s+(i>0 ? ', ' : '') + (x==null ? '' : x), '')} <br></br>
          <b>Leave Type:</b> {props.leaveType} <br></br>
          <b>Leave Reason:</b> {props.reason} <br></br>
          <b>Leave Count:</b> {props.count} <br></br>
          <b>HR Review:</b> {props.hrReview} <br></br>
          <b>Approval Image:</b> <br></br>
          <ImagePopUpModal
            key={props.dateFiled}
            imgUrlIcon={props.approvalImageUrl}
            imgUrl={props.approvalImageUrl}
          />
          { props.isAdmin || props.isSuperAdmin
            ?
            <React.Fragment>
              <br></br>
              <div style={{display:"inline-block", justifyContent:"center"}}>
                <b>Approval/Reject Reason:</b> <br></br> <textarea style={{height:55, width:300}}
                  value={reviewReason} id='reason' onChange={reviewReasonListener}></textarea>
                <br></br>
              </div>
            </React.Fragment>
            :
            null
          }
          { loaderState
            ?
              <React.Fragment>
                <br></br>
                <br></br>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </React.Fragment>
            :
              <React.Fragment>
                <br></br>
                <br></br>
                { pdfImage
                  ?
                    <a href={pdfImage} target="_blank" rel="noreferrer" download>Download Leave PDF</a>
                  :
                    <Button variant='primary' onClick={() => genereatePdfHandler()}>Generate PDF</Button>
                }
              </React.Fragment>
          }
        </Modal.Body>
        <Modal.Footer>
        { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div>
          <Button variant="secondary" onClick={cancelHandler}>
            Exit
          </Button> &nbsp;
          <Button variant='primary' onClick={() => clickHandler(props.displayName, props.dateFiled, true, '', '', reviewReason)}>
            Delete
          </Button> &nbsp;
          { props.isAdmin ?
          <Fragment>
            <Button variant="primary" onClick={() => clickHandler(props.displayName, props.dateFiled, false, 'rejected', '', reviewReason)}>
              Reject
            </Button> &nbsp;
            <Button variant="primary" onClick={() => clickHandler(props.displayName, props.dateFiled, false, 'approved', '', reviewReason)}>
              Approve
            </Button>
          </Fragment>
          : null }
          { props.isSuperAdmin ?
          <Fragment>
            <Button variant="primary" onClick={() => clickHandler(props.displayName, props.dateFiled, false, '', 'rejected', reviewReason)}>
              Reject
            </Button> &nbsp;
            <Button variant="primary" onClick={() => clickHandler(props.displayName, props.dateFiled, false, '', 'approved', reviewReason)}>
              Approve
            </Button>
          </Fragment>
          : null }
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageLeaveModal


