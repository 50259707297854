import { firebaseObserver } from "../../firebaseConfig";
import { useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import ViewTimeInOut from "../../components/ViewTimeInOut";


const ViewTimeInOutReport = () => {

  // User Auth Context
  const { user } = useAuthContext();
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);

  // Old code, remove on next release
  // let username;
  // const navigate = useNavigate()
  // firebase.auth().onAuthStateChanged(function(user) {
  //   if (user) {
  //       user.getIdTokenResult().then(function(data) {
  //         // const isAdmin = data.claims.admin;
  //         username = user.displayName;
  //         // console.log(isAdmin);
  //         // console.log(user.email);
  //       }
  //     );
  //   } else {
  //     alert('Unauthorized access, please login.');
  //     navigate('/');
  //   }
  // });

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    return () => {
      firebaseObserver.unsubscribe('authStateChanged');
    };
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  return (
    <div>
    <MainHeader />
      <center>
        <ViewTimeInOut displayName={displayName}/>
      </center>
    </div>
  );
}

export default ViewTimeInOutReport;
