function TextStatusColor (props) {

  // Color style logic for status states
  // pending = no style
  // approved = blue
  // rejected = red
  // expired = orange

  function isApproved(status) {
    if (status === "approved") {
      return true;
    }
    return false;
  }
  function isRejected(status) {
    if (status === "rejected") {
      return true;
    }
    return false;
  }
  function isExpired(status) {
    if (status === "expired") {
      return true;
    }
    return false;
  }


  return (
    <b style= { isApproved(props.text) ? {color: "blue"}
    : isRejected(props.text) ? {color:"red"}
      : isExpired(props.text) ? {color:"orange"}
        : null }>{props.text}</b>
  )
}

export default TextStatusColor;
